import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import Transparent from '../images/sprite/transparent.gif';
import liveImg from '../images/sprite/cover-live.png';
import Loading from "../images/sprite/loading40.gif";
import Countdown from './Countdown';

import Cookies from 'universal-cookie';


import moment from 'moment';
require('moment-timezone');


const cookies = new Cookies();

cookies.set('dupBet', "2", { path: '/' });

window.book_odd = '';
window.book_oddType = '';
window.book_team = '';
window.book_index = 0;
window.fancy_odd = '';
window.fancy_size = '';
window.fancy_index = -1;


const io = require('socket.io-client');
const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if (ranNum == 1) {
    ENDPOINT = "/data1";
} else if (ranNum == 2) {
    ENDPOINT = "/data2";
} else if (ranNum == 3) {
    ENDPOINT = "/data3";
} else {
    ENDPOINT = "/data4";
}

var socket;

var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0, c13 = 0, c14 = 0, c15 = 0, c16 = 0, c17 = 0, c18 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30, s31, s32, s33, s34, s35, s36;


export default function NewFullmarket(props) {
    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [defaultStake, setdefaultStake] = useState('');
    const [Bookclick, setBookclick] = useState(false)
    const [Bookclick2, setBookclick2] = useState(false)
    const [Bookclick3, setBookclick3] = useState(false)
    const [selectBack, setselectBack] = useState(null)
    const [selectFancy, setselectFancy] = useState(null)
    const [selectFancyLay, setselectFancyLay] = useState(true)
    const [loading, setloading] = useState(false);

    const [Tvopen, setTvopen] = useState(true)
    const [isShown, setisShown] = useState(false)
    const [marketData, setmarketData] = useState({})
    const [fancyData, setfancyData] = useState([])
    const [click, setclick] = useState(-1);
    const [field, setfield] = useState(0);
    const [bookData, setbookData] = useState({})
    const [bOdds, setbOdds] = useState(' ')
    const [fOdds, setfOdds] = useState(' ')
    const [fsize, setfsize] = useState(' ')
    const [ScoreId, setScoreId] = useState('')
    const [oddsType, setoddsType] = useState('Back')
    const [teamName, setteamName] = useState('')
    const [bookData2, setbookData2] = useState({})
    const [acceptAnyOdds, setacceptAnyOdds] = useState(false)
    const [bookBetSelection, setbookBetSelection] = useState(0)
    const [bookMsg, setbookMsg] = useState({})

    const [fancySelection, setfancySelection] = useState(-1)
    const [fancyMsg, setfancyMsg] = useState({})

    const [pnlMarket, setpnlMarket] = useState({})
    const [pnlBook, setpnlBook] = useState({})
    const [pnlFancy, setpnlFancy] = useState([])

    const [pnlMarket2, setpnlMarket2] = useState({})
    const [pnlBook2, setpnlBook2] = useState({})
    const [ScoreData, setScoreData] = useState()
    const [liveStream, setliveStream] = useState(null);
    const [matchEndedMarket, setmatchEndedMarket] = useState(false);
    const [matchEndedBook, setmatchEndedBook] = useState(false);
    const [liquidityStatus, setliquidityStatus] = useState(0);


    const [coinFactor, setcoinFactor] = useState(1);


    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackRate2, setrunner1BackRate2] = useState(' ');
    const [runner1BackRate3, setrunner1BackRate3] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1BackSize2, setrunner1BackSize2] = useState(' ');
    const [runner1BackSize3, setrunner1BackSize3] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LayRate2, setrunner1LayRate2] = useState(' ');
    const [runner1LayRate3, setrunner1LayRate3] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner1LaySize2, setrunner1LaySize2] = useState(' ');
    const [runner1LaySize3, setrunner1LaySize3] = useState(' ');

    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackRate2, setrunner2BackRate2] = useState(' ');
    const [runner2BackRate3, setrunner2BackRate3] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2BackSize2, setrunner2BackSize2] = useState(' ');
    const [runner2BackSize3, setrunner2BackSize3] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LayRate2, setrunner2LayRate2] = useState(' ');
    const [runner2LayRate3, setrunner2LayRate3] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');
    const [runner2LaySize2, setrunner2LaySize2] = useState(' ');
    const [runner2LaySize3, setrunner2LaySize3] = useState(' ');
    const [hidematchOdds, sethidematchOdds] = useState({})
    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieBackRate2, settieBackRate2] = useState(' ');
    const [tieBackRate3, settieBackRate3] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieBackSize2, settieBackSize2] = useState(' ');
    const [tieBackSize3, settieBackSize3] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieLayRate2, settieLayRate2] = useState(' ');
    const [tieLayRate3, settieLayRate3] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [tieLaySize2, settieLaySize2] = useState(' ');
    const [tieLaySize3, settieLaySize3] = useState(' ');
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [ActiveTeam, setActiveTeam] = useState('');
    const [chId, setchId] = useState(null);

    const url = window.location.href;
    const para = url.split('/');

    const eventT = para[6];
    const eventid = para[7];


    useEffect(() => {
        socket = io("https://liveapi247.live",{
            path:ENDPOINT+'/socket.io',
            extraHeaders: {
                id: props.userid,
                token: props.utoken,
            }
        });
        console.log('socket connected');
        socket.on('connect', function (data) {
            socket.emit('room1', eventid);
        });
        return () => {
            socket.close();
            console.log('socket disconnected');
            props.setOnClick(0);
            s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;
            s25 = -1; s26 = -1; s27 = -1; s28 = -1; s29 = -1; s30 = -1; s31 = -1; s32 = -1; s33 = -1; s34 = -1; s35 = -1; s36 = -1;
        }
    }, [])

    useEffect(() => {

        if (!props.userid) return;
        Axios.post('https://liveapi247.live/api1/ssgetclickstake', {
            id: props.userid,
            token: props.utoken,
        }).then(result => {
            if (result.status === 210) {
                setcoinFactor(parseInt(result.data[0].coin_type));
                setdefaultStake(result.data[0].defaultStake);
                setSsstake1(result.data[0].SSstake1);
                setSsstake2(result.data[0].SSstake2);
                setSsstake3(result.data[0].SSstake3);
                setSsstake4(result.data[0].SSstake4);
                setSsstake5(result.data[0].SSstake5);
                setSsstake6(result.data[0].SSstake6);

            }
        })

    }, [props.userid]);



    useEffect(() => {
        if (!eventid) {
            setliveStream(null);
            return;
        }

        Axios.post("https://liveapi247.live/api1/livestreaming", {
            eventid: eventid,
        }).then((result) => {
            if (result.status === 210) {
                if (result.data[0].tvId && result.data[0].tvId != '') {
                    setliveStream(result.data[0].eventId);
                    setScoreData(result.data[0].scoreId);
                    setActiveTeam(result.data[0].activeTeam);
                }
            }
        })

        if (!eventid) return;
        Axios.post('https://liveapi247.live/api8/getTvId', {
            eid: eventid
        }).then(result => {

            if (result.status === 200) {
                setchId(result.data.data.cid);
            }
        })
    }, [eventid]);





    useEffect(() => {
        if (!eventid) return;

        Axios.post('https://liveapi247.live/api1/getFullMarket', {
            eventId: eventid,
        })
            .then(result => {

                if ((result.data.length > 0 && result.data[0].matchStatus != 1) || result.data.length === 0) {
                    setmatchEndedMarket(true);

                }

                if (result.data[0]) {
                    let obj = result.data[0];
                    setrunner1BackRate1(obj.runner1BackRate1);
                    setrunner1BackRate2(obj.runner1BackRate2);
                    setrunner1BackRate3(obj.runner1BackRate3);
                    setrunner1LayRate1(obj.runner1LayRate1);
                    setrunner1LayRate2(obj.runner1LayRate2);
                    setrunner1LayRate3(obj.runner1LayRate3);
                    setrunner2BackRate1(obj.runner2BackRate1);
                    setrunner2BackRate2(obj.runner2BackRate2);
                    setrunner2BackRate3(obj.runner2BackRate3);
                    setrunner2LayRate1(obj.runner2LayRate1);
                    setrunner2LayRate2(obj.runner2LayRate2);
                    setrunner2LayRate3(obj.runner2LayRate3);
                    sethidematchOdds(obj.oddsHide)

                    if (obj.runnerId3) {
                        settieBackRate1(obj.tieBackRate1);
                        settieBackRate2(obj.tieBackRate2);
                        settieBackRate3(obj.tieBackRate3);
                        settieLayRate1(obj.tieLayRate1);
                        settieLayRate2(obj.tieLayRate2);
                        settieLayRate3(obj.tieLayRate3);

                    }

                    obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm')

                    setmarketData(obj);
                }

            })
            .catch(e => {

            });


        Axios.post('https://liveapi247.live/api1/getBookMakerMarket', {

            eventId: eventid,

        })
            .then(result => {

                if (result.data.length === 0 || (result.data.length > 0 && result.data[0].matchStatus != 1)) {
                    setmatchEndedBook(true);

                }


                if (result.data[0]) {
                    let obj = result.data[0];
                    setbookData2(obj);
                }

            })

    }, [eventid]);


    useEffect(() => {
        if (matchEndedBook && matchEndedMarket) {

            window.location.href = '/';
        }
    }
        , [matchEndedBook, matchEndedMarket]);


    useEffect(() => {

        socket.on(eventid, (val) => {

            var value = JSON.parse(val);

            if (value.messageType === 'match_score') {
                let temp = value;
                setScoreData(temp);
            }

            if (value[0] && value[0].messageType === 'session_rate') {
                let temp = value;
                if (value[0].RateMode == 1) {

                    temp.sort(function (a, b) {

                        return moment(a.selectionId) - moment(b.selectionId);
                    });
                }
                else if (value[0].skymode == 'skyRate') {
                    temp = temp.filter((item, index) => item.gameStatus !== 18)
                    temp.sort(function (a, b) {
                        return a.selectionId - b.selectionId;
                    });
                }
                setfancyData(temp);
            }


            if (value.messageType === 'bookmaker_match_rate') {
                //console.log('book1',value);
                let temp = { ...bookData };

                temp.messageType = value.messageType;
                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1BackRate2 = value.runner1BackRate2;
                temp.runner1BackRate3 = value.runner1BackRate3;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner1LayRate2 = value.runner1LayRate2;
                temp.runner1LayRate3 = value.runner1LayRate3;

                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2BackRate2 = value.runner2BackRate2;
                temp.runner2BackRate3 = value.runner2BackRate3;
                temp.runner2LayRate1 = value.runner2LayRate1;
                temp.runner2LayRate2 = value.runner2LayRate2;
                temp.runner2LayRate3 = value.runner2LayRate3;

                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieBackRate2 = value.tieBackRate2;
                temp.tieBackRate3 = value.tieBackRate3;
                temp.tieLayRate1 = value.tieLayRate1;
                temp.tieLayRate2 = value.tieLayRate2;
                temp.tieLayRate3 = value.tieLayRate3;
                //console.log('bookmaker',bookData.gameStatus,'runner1',bookData.runner1Status,'runner2',bookData.runner2Status);

                setbookData(temp);

            }




            if (value.messageType === 'betfair_match_rate') {

                setmarketBetStatus(value.status);

                if (value.status == '0' && liquidityStatus == 0) {
                    setliquidityStatus(1);

                }
                if (s1 !== value.runner1BackRate1) {
                    setrunner1BackRate1(value.runner1BackRate1);
                    s1 = value.runner1BackRate1;
                    c1 = 1;
                    setTimeout(() => { c1 = 0; }, 700);
                }
                if (s2 !== value.runner1BackRate2) {
                    setrunner1BackRate2(value.runner1BackRate2);
                    s2 = value.runner1BackRate2;
                    c2 = 1;
                    setTimeout(() => { c2 = 0; }, 700);
                }
                if (s3 !== value.runner1BackRate3) {
                    setrunner1BackRate3(value.runner1BackRate3);
                    c3 = 1;
                    s3 = value.runner1BackRate3;
                    setTimeout(() => { c3 = 0; }, 700);
                }
                if (s4 !== value.runner1BackSize1) {
                    setrunner1BackSize1(value.runner1BackSize1);
                    s4 = value.runner1BackSize1;
                }
                if (s5 !== value.runner1BackSize2) {
                    setrunner1BackSize2(value.runner1BackSize2);
                    s5 = value.runner1BackSize2;
                }
                if (s6 !== value.runner1BackSize3) {
                    setrunner1BackSize3(value.runner1BackSize3);
                    s6 = value.runner1BackSize3;
                }
                if (s7 !== value.runner1LayRate1) {
                    setrunner1LayRate1(value.runner1LayRate1);
                    s7 = value.runner1LayRate1;
                    c4 = 1;
                    setTimeout(() => { c4 = 0; }, 700);
                }
                if (s8 !== value.runner1LayRate2) {
                    setrunner1LayRate2(value.runner1LayRate2);
                    s8 = value.runner1LayRate2;
                    c5 = 1;
                    setTimeout(() => { c5 = 0; }, 700);
                }
                if (s9 !== value.runner1LayRate3) {
                    setrunner1LayRate3(value.runner1LayRate3);
                    s9 = value.runner1LayRate3;
                    c6 = 1;
                    setTimeout(() => { c6 = 0; }, 700);
                }
                if (s10 !== value.runner1LaySize1) {
                    setrunner1LaySize1(value.runner1LaySize1);
                    s10 = value.runner1LaySize1;
                }
                if (s11 !== value.runner1LaySize2) {
                    setrunner1LaySize2(value.runner1LaySize2);
                    s11 = value.runner1LaySize2;
                }
                if (s12 !== value.runner1LaySize3) {
                    setrunner1LaySize3(value.runner1LaySize3);
                    s12 = value.runner1LaySize3;
                }
                if (s13 !== value.runner2BackRate1) {
                    setrunner2BackRate1(value.runner2BackRate1);
                    s13 = value.runner2BackRate1;
                    c7 = 1;
                    setTimeout(() => { c7 = 0; }, 700);
                }
                if (s14 !== value.runner2BackRate2) {
                    setrunner2BackRate2(value.runner2BackRate2);
                    s14 = value.runner2BackRate2;
                    c8 = 1;
                    setTimeout(() => { c8 = 0; }, 700);
                }
                if (s15 !== value.runner2BackRate3) {
                    setrunner2BackRate3(value.runner2BackRate3);
                    s15 = value.runner2BackRate3;
                    c9 = 1;
                    setTimeout(() => { c9 = 0; }, 700);
                }
                if (s16 !== value.runner2BackSize1) {
                    setrunner2BackSize1(value.runner2BackSize1);
                    s16 = value.runner2BackSize1;
                }
                if (s17 !== value.runner2BackSize2) {
                    setrunner2BackSize2(value.runner2BackSize2);
                    s17 = value.runner2BackSize2;
                }
                if (s18 !== value.runner2BackSize3) {
                    setrunner2BackSize3(value.runner2BackSize3);
                    s18 = value.runner2BackSize3;
                }
                if (s19 !== value.runner2LayRate1) {
                    setrunner2LayRate1(value.runner2LayRate1);
                    s19 = value.runner2LayRate1;
                    c10 = 1;
                    setTimeout(() => { c10 = 0; }, 700);
                }
                if (s20 !== value.runner2LayRate2) {
                    setrunner2LayRate2(value.runner2LayRate2);
                    s20 = value.runner2LayRate2;
                    c11 = 1;
                    setTimeout(() => { c11 = 0; }, 700);
                }
                if (s21 !== value.runner2LayRate3) {
                    setrunner2LayRate3(value.runner2LayRate3);
                    s21 = value.runner2LayRate3;
                    c12 = 1;
                    setTimeout(() => { c12 = 0; }, 700);
                }
                if (s22 !== value.runner2LaySize1) {
                    setrunner2LaySize1(value.runner2LaySize1);
                    s22 = value.runner2LaySize1;
                }
                if (s23 !== value.runner2LaySize2) {
                    setrunner2LaySize2(value.runner2LaySize2);
                    s23 = value.runner2LaySize2;
                }
                if (s24 !== value.runner2LaySize3) {
                    setrunner2LaySize3(value.runner2LaySize3);
                    s24 = value.runner2LaySize3;
                }


                if (s25 !== value.tieBackRate1) {
                    settieBackRate1(value.tieBackRate1);
                    s25 = value.tieBackRate1;
                    c13 = 1;
                    setTimeout(() => { c13 = 0; }, 700);
                }
                if (s26 !== value.tieBackRate2) {
                    settieBackRate2(value.tieBackRate2);
                    s26 = value.tieBackRate2;
                    c14 = 1;
                    setTimeout(() => { c14 = 0; }, 700);
                }
                if (s27 !== value.tieBackRate3) {
                    settieBackRate3(value.tieBackRate3);
                    s27 = value.tieBackRate3;
                    c15 = 1;
                    setTimeout(() => { c15 = 0; }, 700);
                }
                if (s28 !== value.tieBackSize1) {
                    settieBackSize1(value.tieBackSize1);
                    s28 = value.tieBackSize1;
                }
                if (s29 !== value.tieBackSize2) {
                    settieBackSize2(value.tieBackSize2);
                    s29 = value.tieBackSize2;
                }
                if (s30 !== value.tieBackSize3) {
                    settieBackSize3(value.tieBackSize3);
                    s30 = value.tieBackSize3;
                }
                if (s31 !== value.tieLayRate1) {
                    settieLayRate1(value.tieLayRate1);
                    s31 = value.tieLayRate1;
                    c16 = 1;
                    setTimeout(() => { c16 = 0; }, 700);
                }
                if (s32 !== value.tieLayRate2) {
                    settieLayRate2(value.tieLayRate2);
                    s32 = value.tieLayRate2;
                    c17 = 1;
                    setTimeout(() => { c17 = 0; }, 700);
                }
                if (s33 !== value.tieLayRate3) {
                    settieLayRate3(value.tieLayRate3);
                    s33 = value.tieLayRate3;
                    c18 = 1;
                    setTimeout(() => { c18 = 0; }, 700);
                }
                if (s34 !== value.tieLaySize1) {
                    settieLaySize1(value.tieLaySize1);
                    s34 = value.tieLaySize1;
                }
                if (s35 !== value.tieLaySize2) {
                    settieLaySize2(value.tieLaySize2);
                    s35 = value.tieLaySize2;
                }
                if (s36 !== value.tieLaySize3) {
                    settieLaySize3(value.tieLaySize3);
                    s36 = value.tieLaySize3;
                }

            }
        })

        return () => {
            socket.removeAllListeners(eventid);
            setrunner1BackRate1(' '); setrunner1BackRate2(' '); setrunner1BackRate3(' '); setrunner2BackRate1(' '); setrunner2BackRate2(' '); setrunner2BackRate3(' ');
            setrunner1LayRate1(' '); setrunner1LayRate2(' '); setrunner1LayRate3(' '); setrunner2LayRate1(' '); setrunner2LayRate2(' '); setrunner2LayRate3(' ');
            setrunner1BackSize1(' '); setrunner1BackSize2(' '); setrunner1BackSize3(' '); setrunner2BackSize1(' '); setrunner2BackSize2(' '); setrunner2BackSize3(' ');
            setrunner1LaySize1(' '); setrunner1LaySize2(' '); setrunner1LaySize3(' '); setrunner2LaySize1(' '); setrunner2LaySize2(' '); setrunner2LaySize3(' ');
            settieBackRate1(' '); settieBackRate2(' '); settieBackRate3(' '); settieLayRate1(' '); settieLayRate2(' '); settieLayRate3(' '); settieBackSize1(' ');
            settieBackSize2(' '); settieBackSize3(' '); settieLaySize1(' '); settieLaySize2(' '); settieLaySize3(' ');
            setfancyData([]);
        }

    }, [eventid])

    const changeDefaultStake = (event) => {
        setdefaultStake(event.target.value);
    };

    const handle_Refresh = () => {

        const utoken = cookies.get('token');
        const user = cookies.get('id');
        Axios.post('https://liveapi247.live/api1/refresh', {
            id: user,
            token: utoken
        })
            .then(result => {
                if (result.status === 210) {


                    if (result.data[0].exposureBalance) {
                        props.updateBalance(result.data[0].val - Math.abs(result.data[0].exposureBalance), Math.abs(result.data[0].exposureBalance));
                        //props.changeExposure(Math.abs(result.data[1].val));
                    }
                    else {
                        props.updateBalance(result.data[0].mainBalance - Math.abs(result.data[0].exposureBalance), 0);
                        //props.changeExposure(0);
                    }
                }
            })
    };



    const placeBet = () => {

        var teamNumber = 0;

        if ((teamName === 'A' && !props.oneclick) || (props.oneclick && window.book_team === 'A')) {
            teamNumber = 1;
        }
        else if ((teamName === 'B' && !props.oneclick) || (props.oneclick && window.book_team === 'B')) {
            teamNumber = 2;
        }
        else if ((teamName === 'T' && !props.oneclick) || (props.oneclick && window.book_team === 'T')) {
            teamNumber = 3;
        }

        var book_Stake = defaultStake;
        var book_odds = (bOdds / 100 + 1);
        var book_oddT = oddsType;
        var book_teamName = teamName;
        if (props.oneclick === true) {
            book_Stake = window.oneclickStake;
            book_odds = (window.book_odd / 100 + 1);
            book_oddT = window.book_oddType;
            book_teamName = window.book_team;

        }
        book_odds = parseFloat(book_odds).toFixed(2).toString();
        var dupBet = cookies.get('dupBet');


        Axios.post('https://liveapi247.live/api3/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId,
            odds: book_odds,
            oddsType: book_oddT,
            betType: 'match',
            coin: book_Stake,
            teamName: book_teamName,
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }).then(result => {

            if (result.data == 6) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 1;
                obj.msg = 'Bet Placed Successfully!';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
                props.isanybets(!props.anyBets);
                handle_Refresh();
                plBook();
                //handleRefresh();   
            }
            else if (result.data == 1) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Bet Blocked !';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }

            else if (result.data == 2) {

                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Bet Closed !';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data == 3) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Insufficient Coin !';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data == 4) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Odds Changed !';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data == 5) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Bet can not be placed!';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data == 7) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Enter valid coin value!';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data == 123) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Bet Over 75 not accepted!';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data == 13) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Duplicate Bet Not allowed!';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data.charAt(0) == 8) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                let arr = result.data.split('_');
                obj.msg = 'Min bet is' + ' ' + arr[1];
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else if (result.data.charAt(0) == 9) {
                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                let arr = result.data.split('_');
                obj.msg = 'Maximum bet is' + ' ' + arr[1];
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }
            else {

                var obj = {};
                obj.number = teamNumber;
                obj.msgNumber = 2;
                obj.msg = 'Bet can not be placed!';
                setbookMsg(obj);

                setTimeout(() => {
                    setbookMsg({});
                }, 2000);
            }

        })

    };

    const fancyBet = (selectionId, fld, index) => {

        var teamNumber = index;


        var dupBet = cookies.get('dupBet');
        var oddT;
        if (fld === 1) {
            oddT = 'NO';
        }
        else if (fld === 2) {
            oddT = 'YES';
        }
        var fancy_odds = fOdds;
        var fancy_stake = defaultStake;
        var fancy_teamName = fsize;

        if (props.oneclick) {
            teamNumber = window.fancy_index;
            fancy_odds = window.fancy_odd;
            fancy_stake = window.oneclickStake;
            fancy_teamName = window.fancy_size;

        }


        Axios.post('https://liveapi247.live/api3/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: selectionId, //fancy selection id
            odds: fancy_odds,                  //run 
            oddsType: oddT,           //YES NO
            betType: 'fancy',            //fancy
            coin: fancy_stake,
            teamName: fancy_teamName,          //rate 110,100
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {


                if (result.data == 6) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 1;
                    obj.msg = 'Bet Placed Successfully!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                    props.isanybets(!props.anyBets);
                    handle_Refresh();
                    plFancy();
                    //handleRefresh();   
                }
                else if (result.data == 1) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Blocked !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }

                else if (result.data == 2) {

                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Closed !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 3) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Insufficient Coin !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 4) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'fancy Changed !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 5) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet can not be placed!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 7) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Enter valid coin value!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 123) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Over 75 not accepted!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 13) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Duplicate Bet Not allowed!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data.charAt(0) == 8) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    let arr = result.data.split('_');
                    obj.msg = 'Min bet is' + ' ' + arr[1];
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data.charAt(0) == 9) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    let arr = result.data.split('_');
                    obj.msg = 'Max bet is' + ' ' + arr[1];
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else {

                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Duplicate Bet Not allowed!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }

            })

    };





    const plMarket = () => {

        if (!props.userid) return;
        Axios.post('https://liveapi247.live/api1/plmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: marketData.marketId

        }).then(result => {
            if (result.status === 210) {
                let obj = result.data;
                setpnlMarket(obj);
            }
        })
    }


    const plBook = () => {
        if (!props.userid) return;
        Axios.post('https://liveapi247.live/api1/plmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId
        })
            .then(result => {
                if (result.status === 210) {
                    let obj = result.data;
                    setpnlBook(obj);
                }
            })

    }



    const plFancy = () => {
        if (!props.userid || !eventid) return;
        Axios.post('https://liveapi247.live/api1/plfancy', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = {};
                    result.data.map((item) => {
                        obj[item.marketId] = item.exposure;
                    });
                    setpnlFancy(obj);


                }

                if (result.status === 220) {


                }
            })
    }



    useEffect(() => {

        if (marketData.matchStatus == 1) plMarket();
        if (bookData2.matchStatus == 1) plBook();

        return () => {

            setpnlMarket({});
            setpnlBook({});

        }
    }, [props.userid, bookData2.marketId, marketData.marketId, props.anyBets])




    useEffect(() => {
        plFancy();

    }, [props.userid, eventid])


    useEffect(() => {


        if (props.betClick) {

            var amount = props.dStake;
            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (pnlMarket.teamA && pnlMarket.teamB && pnlMarket.tie && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0)) {
                win_teamA = parseFloat(pnlMarket.teamA);
                win_teamB = parseFloat(pnlMarket.teamB);
                win_tie = parseFloat(pnlMarket.tie);

            }

            if (props.betType == 'LAGAI') {
                if (props.team == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(props.odds - 1);
                }
                else if (props.team == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(props.odds - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(props.odds - 1);

                }
            }

            else {

                if (props.team == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(props.odds - 1);

                }
                else if (props.team == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(props.odds - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(props.odds - 1);

                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);


            setpnlMarket2(obj);

        }

        else if (Bookclick || Bookclick2 || Bookclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlBook.teamA && pnlBook.teamB && pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0)) {
                win_teamA = parseFloat(pnlBook.teamA);
                win_teamB = parseFloat(pnlBook.teamB);
                win_tie = parseFloat(pnlBook.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(bOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);

            setpnlBook2(obj);
        }

    }, [defaultStake, props.dStake, props.odds, props.betType, props.team])



    const changeBook = (val1, val2, val3) => {

        if (val2 === 2) {
            setBookclick(true);
            setBookclick2(false);
            setBookclick3(false);
            setteamName('B');
        }
        else if (val2 === 1) {
            setBookclick2(true);
            setBookclick(false);
            setBookclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {

            setBookclick3(true);
            setBookclick2(false);
            setBookclick(false);
            setteamName('T');
        }
        setselectBack(val1);
        setbOdds(val3);
        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    const closeBookBet = () => {

        setselectBack(0);
        setBookclick2(false);
        setBookclick(false)
        setBookclick3(false);

    }

    const OpenfancyBet = (index, price, size, selectionId) => {

        if (props.oneclick) {

            window.fancy_odd = price;
            window.fancy_size = size;
            window.fancy_index = index;
            setTimeout(() => { window.fancy_index = -1; }, 3000);
            fancyBet(selectionId, 1, index);


        }

        setclick(index);
        setselectFancy(1);
        setfield(1);

    }

    const OpenfancyBetLay = (index, price, size, selectionId) => {

        if (props.oneclick) {
            window.fancy_odd = price;
            window.fancy_size = size;
            window.fancy_index = index;
            setTimeout(() => { window.fancy_index = -1; }, 3000);
            fancyBet(selectionId, 2, index);

        }

        setclick(index);
        setselectFancyLay(1);
        setfield(2);

    }

    const closeFancyBet = () => {
        setclick(-1);
        setselectFancy(0);
        setselectFancyLay(0);
    }

    const handleRefresh = () => {
        setloading(true);
        setTimeout(() => { setloading(false); }, 1000);
    }

    const ChangeTv = () => {
        setTvopen(true)
    }

    const ChangeSetTv = () => {
        setTvopen(false)
    }

    const handleMatchOddsBet = (val1, val2, val3, val4, val5, val6, val7, val8) => {

        if (props.oneclick == true) {
            props.changeWaiting(true);

            window.eventIId = eventid;
            window.marketIId = marketData.marketId;
            window.odds_oneClick = val2;

            props.oneClickMarket();

        } else {
            props.showBetForm(val1, val2, val3, val4, val5, val6, val7, val8);
        }

    }

    const handleBookBets = (val1, val2, val3) => {

        if (props.oneclick == true) {
            if (val2 === 1) {
                window.book_index = 1;
                setTimeout(() => { window.book_index = 0; }, 5000);
                window.book_team = 'A';
            }
            else if (val2 === 2) {

                window.book_index = 2;
                setTimeout(() => { window.book_index = 0; }, 5000);
                window.book_team = 'B';
            }


            else if (val2 === 3) {
                window.book_index = 3;
                setTimeout(() => { window.book_index = 0; }, 5000);
                window.book_team = 'T';
            }


            if (val1 === 1 || val1 === 3 || val1 === 5) {

                window.book_oddType = 'LAGAI';

            }
            else if (val1 === 2 || val1 === 4 || val1 === 6) {

                window.book_oddType = 'KHAI';
            }
            setselectBack(val1);

            window.book_odd = val3;
            //setTimeout(()=>{placeBet()},4800);
            placeBet();
        }
        else {
            changeBook(val1, val2, val3);
        }

    }


    useEffect(() => {

        if (ActiveTeam == '1') {
            Axios.get(`https://lt-fn-cdn001.akamaized.net/techablesoftware/en/Etc:UTC/gismo/match_info/m` + eventid).then((result) => {
                var MatchId = result.data.queryUrl;
                var matches = MatchId.match(/(\d+)/);
                setScoreId(matches[0]);
            })
        }
    }, [ActiveTeam])


    return (
        <React.Fragment>
            {loading === true && <div id="loading" class="loading-wrap" style={{ top: '100px' }}>
                <ul class="loading">
                    <li><img src={Loading} /></li>
                    <li>Loading...
                    </li>
                    <br />
                    <li><span id="progress"></span></li>
                </ul>
            </div>}
            <div _ngcontent-njs-c61="" id="overWrap" class="lit-content-center live-match" style={props.isLoggedIn ? props.oneclick ? { height: "calc(100% - 55px)" } : { height: "calc(100% - 26px)" } : { height: "calc(100% - 0px)" }}>
                {eventT != 2378962 &&
                    <div _ngcontent-njs-c61="" id="liveMatchGameHead" class="game-head">


                        <div _ngcontent-njs-c61="" id="matchTrackerWrap" class="match-tracker-wrap">
                            {(ScoreData && ScoreData !== undefined) && ScoreData != eventid ? (
                                <iframe style={{ height: '180px', width: '100%', border: 0 }}
                                    autoplay="" preload="" playsinline=""
                                    title="mob-live-stream"
                                    allowFullScreen={false}
                                    src={`https://gameex.bet/Home/cricketboard/?id=${ScoreData}&sport_id=${eventT}`}
                                    sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                                </iframe>
                            ) : ScoreId &&
                            (<iframe style={{ height: '182px', width: '100%', border: 0 }}
                                autoplay="" preload="" playsinline=""
                                title="mob-live-stream"
                                allowFullScreen={false}
                                src={`https://gameex.bet/Home/cricketboard/?id=${ScoreData}&sport_id=${eventT}`}
                                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                            </iframe>)}

                            <ul _ngcontent-njs-c61="" id="liveMatchTrackerBtn" class="fm-match-button">
                                <li _ngcontent-njs-c61="" ><a _ngcontent-njs-c61="" id="liveMultiMarketPin" style={{ cursor: 'pointer' }} name="gameHeadPin" class="favourite-pin" title="Add to Multi Markets" eventtype="4" eventid="30035305" marketid="1.173506114"></a></li>
                                <li _ngcontent-njs-c61="" >
                                    <a _ngcontent-njs-c61="" style={{ cursor: 'pointer' }} onClick={handleRefresh} class="fm-refresh-button">

                                    </a></li>
                            </ul>
                        </div>

                        {/*<!-- Game Scroes Event -->*/}
                        <div _ngcontent-njs-c61="" id="scoresEvent" class="fm-match-odds">Match Odds<img src={Transparent} /></div>




                        {/*<!-- Game Information -->*/}
                        <ul _ngcontent-njs-c61="" class="game-info" style={{ display: 'block' }}>
                            {moment() < moment(marketData.marketStartTime) && <li _ngcontent-njs-c61="" id="gameInfoDate" class=""><img _ngcontent-njs-c61="" class="icon-time" src={Transparent} />{marketData.marketStartTime}</li>}
                            {moment() >= moment(marketData.marketStartTime) && <li _ngcontent-njs-c61="" id="gameInfoDate" class="green"><img _ngcontent-njs-c61="" class="icon-irun" src={Transparent} /> In-Play</li>}
                            {liquidityStatus == 1 && fancyData.length == 0 && ((bookData2.matchStatus == 1 && bookData.runner1Status == 2 && bookData.runner2Status == 2 && bookData.runner3Status == 2) || bookData2.matchStatus != 1 || (bookData2.matchStatus == 1 && bookData.gameStatus != 2)) && <li><span id="lowLiquidityTag" class="game-low_liq" style={{ padding: "0px", color: "#1e1e1e" }} >Low Liquidity</span></li>}

                        </ul>

                        {props.isLoggedIn === true && liveStream && liveStream != '' &&

                            <a _ngcontent-njs-c61="" id="lmtOpenStreaming" class="open-tv-wrap" onClick={ChangeTv}>Live</a>}



                    </div>}
                {/* {Tvopen === true &&  moment() >= moment(marketData.marketStartTime) && props.isLoggedIn  && liveStream && liveStream !='' &&
<Hls  liveStream ={liveStream}/>} */}
                {Tvopen === true && moment() >= moment(marketData.marketStartTime) && props.isLoggedIn &&
                    <div _ngcontent-njs-c67="" id="tv-wrap" class="game-streaming-wrap" onMouseEnter={() => setisShown(true)} onMouseLeave={() => setisShown(false)}>
                        <div _ngcontent-njs-c67="" id="tv" class="tv">
                            <img src={liveImg} style={{ width: '100%', height: '100%', position: 'absolute' }} />
                            {isShown &&
                                <ul _ngcontent-njs-c67="" class="tv-control">
                                    <li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" class="close" id="closeStreamingBox" onClick={ChangeSetTv}></a></li>
                                </ul>}
                            <iframe style={{ height: '100%', width: '100%', border: 0, zIndex: 100, position: 'absolute' }}
                                autoplay="" preload="" playsinline=""
                                title="mob-live-stream"
                                allowFullScreen={false}
                                src={`https://ss247.info/api/Nstream/2/${chId}`}
                                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                            </iframe>
                        </div>
                    </div>
                }

                {/*  <!-- Markets Closed Wrap -->*/}



                {/*   <!-- Game Bets Table -->*/}
                {marketData.matchStatus == 1 && hidematchOdds != '1' && <div _ngcontent-njs-c61="" id="fullMarketBetsWrap" class="bets-wrap" eventtype="4" eventid="30035305" marketid="1.173506114">



                    <span _ngcontent-njs-c61="" id="selectionCounts" class="refer_only">{marketData.runnerId3 && marketData.runnerId3 != ' ' ? '3 Selections' : '2 Selections'}</span>

                    <table _ngcontent-njs-c61="" id="fullMarketBoard" style={(marketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`fm-bets ${(marketBetStatus != 1) ? "disabled" : ""}`}>
                        <tbody _ngcontent-njs-c61="" ><tr _ngcontent-njs-c61="" class="fm-bet-zone">
                            <td _ngcontent-njs-c61="" ></td>
                            <td _ngcontent-njs-c61="" id="backPercent" class="refer-bet" colspan="2">101.7%</td>
                            <td _ngcontent-njs-c61="" ><a _ngcontent-njs-c61="" id="backAll" class="back-all"><img _ngcontent-njs-c61="" src={Transparent} /><span>  Back all</span></a></td>
                            <td _ngcontent-njs-c61="" ><a _ngcontent-njs-c61="" id="layAll" class="lay-all"><img _ngcontent-njs-c61="" src={Transparent} /><span>Lay all</span></a></td>
                            <td _ngcontent-njs-c61="" id="layPercent" class="refer-book" colspan="2">97.9%</td>
                        </tr>



                            <tr _ngcontent-njs-c61="" id="fullSelection_4164048" eventtype="4" eventid="30035305" marketid="1_173506114" selectionid="4164048" eventname="Royal Challengers Bangalore v Rajasthan Royals" status="1" sortpriority="1" style={(runner1BackRate1 >= 25) ? { pointerEvents: 'none' } : null} className={`${(runner1BackRate1 >= 25) ? "disabled" : "null"}`}>
                                <th _ngcontent-njs-c61="" class="predict">




                                    <p _ngcontent-njs-c61="" ><a _ngcontent-njs-c61="" ><img _ngcontent-njs-c61="" class="icon-predict" src={Transparent} /></a>{marketData.runnerName1}</p>
                                    {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <span id="withoutBet" className={`${pnlMarket.teamA > 0 ? "win" : "lose"}`} >{pnlMarket.teamA > 0 ? pnlMarket.teamA : '(' + Math.abs(pnlMarket.teamA) + ')'}</span>}

                                    {props.betClick && props.dStake != ' ' && props.dStake != '' && <span id="after" className={`${pnlMarket2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamA > 0 ? pnlMarket2.teamA : '(' + Math.abs(pnlMarket2.teamA) + ')'}</span>}



                                </th>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1BackRate3 === ' ') return; props.setOnClick(1); handleMatchOddsBet(true, runner1BackRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="back_3" className={`back-3 ${(c3 === 1) ? "spark" : "null"}`} side="Back" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.88" inplay="0"><a>{runner1BackRate3}<span>{runner1BackSize3}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1BackRate2 === ' ') return; props.setOnClick(1); handleMatchOddsBet(true, runner1BackRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="back_2" className={`back-2 ${(c2 === 1) ? "spark" : "null"} `} side="Back" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.89" inplay="0"><a>{runner1BackRate2}<span>{runner1BackSize2}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1BackRate1 === ' ') return; props.setOnClick(1); handleMatchOddsBet(true, runner1BackRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="back_1" className={`back-1 ${(props.onSelect === 1) ? "select" : "null"} ${(c1 === 1) ? "spark" : "null"}`} side="Back" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.9" inplay="0"><a>{runner1BackRate1}<span>{runner1BackSize1}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1LayRate1 === ' ') return; props.setOnClick(2); handleMatchOddsBet(true, runner1LayRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="lay_1" className={`lay-1 ${(props.onSelect === 2) ? "select" : "null"} ${(c4 === 1) ? "spark" : "null"}`} side="Lay" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.97" inplay="0"><a>{runner1LayRate1}<span>{runner1LaySize1}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1LayRate2 === ' ') return; props.setOnClick(2); handleMatchOddsBet(true, runner1LayRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="lay_2" className={`lay-2 ${(c5 === 1) ? "spark" : "null"}`} side="Lay" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.98" inplay="0"><a>{runner1LayRate2}<span>{runner1LaySize2}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1LayRate3 === ' ') return; props.setOnClick(2); handleMatchOddsBet(true, runner1LayRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="lay_3" className={`lay-3 ${(c6 === 1) ? "spark" : "null"}`} side="Lay" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay="0"><a>{runner1LayRate3}<span>{runner1LaySize3}</span></a></td>
                            </tr>




                            <tr _ngcontent-njs-c61="" id="fullSelection_2954266" eventtype="4" eventid="30035305" marketid="1_173506114" selectionid="2954266" eventname="Royal Challengers Bangalore v Rajasthan Royals" status="1" sortpriority="2" style={(runner2BackRate1 >= 25) ? { pointerEvents: 'none' } : null} className={`${(runner2BackRate1 >= 25) ? "disabled" : "null"}`}>
                                <th _ngcontent-njs-c61="" class="predict">



                                    <p _ngcontent-njs-c61="" ><a><img _ngcontent-njs-c61="" class="icon-predict" src={Transparent} /></a>{marketData.runnerName2}</p>

                                    {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <span id="withoutBet" className={`${pnlMarket.teamB > 0 ? "win" : "lose"}`} >{pnlMarket.teamB > 0 ? pnlMarket.teamB : '(' + Math.abs(pnlMarket.teamB) + ')'}</span>}

                                    {props.betClick && props.dStake != ' ' && props.dStake != '' && <span id="after" className={`${pnlMarket2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamB > 0 ? pnlMarket2.teamB : '(' + Math.abs(pnlMarket2.teamB) + ')'}</span>}



                                </th>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2BackRate3 === ' ') return; props.setOnClick(3); handleMatchOddsBet(true, runner2BackRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="back_3" className={`back-3  ${(c9 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.96" inplay="0"  ><a>{runner2BackRate3}<span>{runner2BackSize3}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2BackRate2 === ' ') return; props.setOnClick(3); handleMatchOddsBet(true, runner2BackRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="back_2" className={`back-2 ${(c8 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.02" inplay="0" ><a>{runner2BackRate2}<span>{runner2BackSize2}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2BackRate1 === ' ') return; props.setOnClick(3); handleMatchOddsBet(true, runner2BackRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="back_1" className={`back-1 ${(props.onSelect === 3) ? "select" : "null"} ${(c7 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay="0" ><a>{runner2BackRate1}<span>{runner2BackSize1}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2LayRate1 === ' ') return; props.setOnClick(4); handleMatchOddsBet(true, runner2LayRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="lay_1" className={`lay-1 ${(props.onSelect === 4) ? "select" : "null"} ${(c10 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.12" inplay="0" ><a>{runner2LayRate1}<span>{runner2LaySize1}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2LayRate2 === ' ') return; props.setOnClick(4); handleMatchOddsBet(true, runner2LayRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="lay_2" className={`lay-2 ${(c11 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.14" inplay="0" ><a>{runner2LayRate2}<span>{runner2LaySize2}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2LayRate3 === ' ') return; props.setOnClick(4); handleMatchOddsBet(true, runner2LayRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="lay_3" className={`lay-3 ${(c12 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.16" inplay="0" ><a>{runner2LayRate3}<span>{runner2LaySize3}</span></a></td>
                            </tr>


                            {marketData.runnerId3 && marketData.runnerId3 != ' ' && <tr id="fullSelection_2954266" eventtype="4" eventid="30035305" marketid="1_173506114" selectionid="2954266" eventname="Royal Challengers Bangalore v Rajasthan Royals" status="1" sortpriority="2" style={(tieBackRate1 >= 25) ? { pointerEvents: 'none' } : null} className={`${(tieBackRate1 >= 25) ? "disabled" : "null"}`}>
                                <th _ngcontent-njs-c61="" class="predict">




                                    <p _ngcontent-njs-c61="" ><a _ngcontent-njs-c61="" ><img _ngcontent-njs-c61="" class="icon-predict" src={Transparent} /></a>The Draw</p>

                                    {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <span id="withoutBet" className={`${pnlMarket.tie > 0 ? "win" : "lose"}`} >{pnlMarket.tie > 0 ? pnlMarket.tie : '(' + Math.abs(pnlMarket.tie) + ')'}</span>}

                                    {props.betClick && props.dStake != ' ' && props.dStake != '' && <span id="after" className={`${pnlMarket2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.tie > 0 ? pnlMarket2.tie : '(' + Math.abs(pnlMarket2.tie) + ')'}</span>}



                                </th>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieBackRate3 === ' ') return; props.setOnClick(5); handleMatchOddsBet(true, tieBackRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="back_3" className={`back-3  ${(c15 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.96" inplay="0"  ><a>{tieBackRate3}<span>{tieBackSize3}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieBackRate2 === ' ') return; props.setOnClick(5); handleMatchOddsBet(true, tieBackRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="back_2" className={`back-2 ${(c14 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.02" inplay="0" ><a>{tieBackRate2}<span>{tieBackSize2}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieBackRate1 === ' ') return; props.setOnClick(5); handleMatchOddsBet(true, tieBackRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="back_1" className={`back-1 ${(props.onSelect === 5) ? "select" : "null"} ${(c13 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay="0" ><a>{tieBackRate1}<span>{tieBackSize1}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieLayRate1 === ' ') return; props.setOnClick(6); handleMatchOddsBet(true, tieLayRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="lay_1" className={`lay-1 ${(props.onSelect === 6) ? "select" : "null"} ${(c16 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.12" inplay="0" ><a>{tieLayRate1}<span>{tieLaySize1}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieLayRate2 === ' ') return; props.setOnClick(6); handleMatchOddsBet(true, tieLayRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="lay_2" className={`lay-2 ${(c17 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.14" inplay="0" ><a>{tieLayRate2}<span>{tieLaySize2}</span></a></td>

                                <td _ngcontent-njs-c61="" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieLayRate3 === ' ') return; props.setOnClick(6); handleMatchOddsBet(true, tieLayRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="lay_3" className={`lay-3 ${(c18 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.16" inplay="0" ><a>{tieLayRate3}<span>{tieLaySize3}</span></a></td>
                            </tr>}


                        </tbody></table>

                </div>}

                {bookData2.matchStatus == 1 &&
                    <div _ngcontent-njs-c61="" id="bookMakerWrap" class="bets-wrap  bookmaker_bet" >

                        <table _ngcontent-njs-c61="" id="bookMakerMarketList" class="fm-bets bookmaker_bet" >
                            <colgroup>
                                <col span="1" width="280" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                            </colgroup>


                            <tbody _ngcontent-njs-c61="" id="bookMakerMarket_30035305_37909" style={{ display: ' table-row-group' }}>

                                <tr _ngcontent-njs-c61="" id="bookMakerSpecialBet" class="special_bet">
                                    <td _ngcontent-njs-c61="" colspan="7">
                                        <h3>
                                            <a _ngcontent-njs-c61="" id="multiMarketPin" class="add-favourite" title="Add to Multi Markets">Add Pin</a>
                                            Bookmaker Market
                                            <p _ngcontent-njs-c61="" >| Zero Commission</p>
                                        </h3>
                                        <dl _ngcontent-njs-c61="" class="fancy-info">
                                            <dt _ngcontent-njs-c61="" ><span>Min</span></dt>
                                            <dd _ngcontent-njs-c61="" id="min">{parseInt(bookData2.minStack) * coinFactor}</dd>
                                            <dt _ngcontent-njs-c61="" ><span>Max</span></dt>
                                            <dd _ngcontent-njs-c61="" id="max">{parseInt(bookData2.maxStack) * coinFactor}</dd>

                                        </dl>
                                    </td>
                                </tr>

                                <tr _ngcontent-njs-c61="" class="fm-bet-zone">
                                    <td _ngcontent-njs-c61="" colspan="3"></td>
                                    {/*  <!-- <td class="refer-bet"></td> --> */}
                                    <td _ngcontent-njs-c61="" >Back</td>
                                    <td _ngcontent-njs-c61="" >Lay</td>
                                    <td _ngcontent-njs-c61="" class="refer-book" colspan="2"></td>
                                </tr>

                                {(bookData.gameStatus != 2 || bookData.runner1Status == 2) && <tr _ngcontent-njs-c61="" id="bookMakerSuspend_30035305_37909_111798" class="fancy-suspend-tr" marketid="37909">
                                    <th _ngcontent-njs-c61="" colspan=""></th>
                                    <td class="fancy-suspend-td" colspan="6">
                                        <div _ngcontent-njs-c61="" id="suspendClass" class="fancy-suspend"><span id="info">{bookData.gameStatus == 10 && bookData.runner1Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                    </td>
                                </tr>}

                                <tr _ngcontent-njs-c61="" id="bookMakerSelection_30035305_37909_111798" style={{ display: 'table-row' }}>
                                    <th _ngcontent-njs-c61="" class="predict">
                                        <p _ngcontent-njs-c61="" id="runnerName">{marketData.runnerName1}</p>

                                        {pnlBook.teamA && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <span _ngcontent-njs-c61="" id="before" className={`${pnlBook.teamA > 0 ? "win" : "lose"}`} >{pnlBook.teamA > 0 ? pnlBook.teamA : '(' + Math.abs(pnlBook.teamA) + ')'}</span>}

                                        {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <span _ngcontent-njs-c61="" id="after" className={`${pnlBook2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamA > 0 ? pnlBook2.teamA : '(' + Math.abs(pnlBook2.teamA) + ')'}</span>}



                                    </th>
                                    <td _ngcontent-njs-c61="" class="" colspan="3">
                                        <dl _ngcontent-njs-c61="" class="back-gradient">
                                            <dd _ngcontent-njs-c61="" id="back_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1BackRate3 === ' ') return; handleBookBets(1, 1, bookData.runner1BackRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1BackRate3}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="back_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1BackRate2 === ' ') return; handleBookBets(1, 1, bookData.runner1BackRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1BackRate2}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1BackRate1 === ' ') return; handleBookBets(1, 1, bookData.runner1BackRate1) }} className={`${(selectBack === 1) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner1BackRate1}</a></dd>
                                        </dl>
                                    </td>
                                    <td _ngcontent-njs-c61="" class="" colspan="3">
                                        <dl _ngcontent-njs-c61="" class="lay-gradient">
                                            <dd _ngcontent-njs-c61="" id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1LayRate1 === ' ') return; handleBookBets(2, 1, bookData.runner1LayRate1) }} className={`${(selectBack === 2) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner1LayRate1}</a></dd>
                                            <dd _ngcontent-njs-c61="" id="lay_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1LayRate2 === ' ') return; handleBookBets(2, 1, bookData.runner1LayRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1LayRate2}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="lay_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1LayRate3 === ' ') return; handleBookBets(2, 1, bookData.runner1LayRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1LayRate3}</p></dd>
                                        </dl>
                                    </td>
                                </tr>

                                {(Bookclick2 === true && !(bookData.gameStatus != 2 || bookData.runner1Status == 2)) &&
                                    <tr _ngcontent-njs-c61="" id="bookMakerBetBoard_30045511_40466_119397_1" class="fancy-quick-tr">
                                        <td _ngcontent-njs-c61="" colspan="7">
                                            <dl _ngcontent-njs-c61="" id="classWrap" className={`quick_bet-wrap ${(selectBack === 1) ? "slip-back" : "slip-lay"}`} >
                                                <dt _ngcontent-njs-c61="" id="bookMakerBetHeader">

                                                    <span _ngcontent-njs-c61="" id="bookMakerBetAcceptCheck" class="bet-check">
                                                        <input _ngcontent-njs-c61="" onChange={() => { setacceptAnyOdds(!acceptAnyOdds) }} id="bookMakerBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                        <label _ngcontent-njs-c61="" htmlFor="bookMakerBetAcceptAnyOdds">Accept Any Odds</label>
                                                    </span>

                                                </dt>
                                                <dd _ngcontent-njs-c61="" class="col-btn"><a id="cancel" onClick={() => { closeBookBet(); }} class="btn" style={{ cursor: 'pointer' }}>Cancel
                                                </a></dd>
                                                <dd _ngcontent-njs-c61="" id="oddsHeader" class="col-odd">
                                                    <ul _ngcontent-njs-c61="" class="quick-bet-confirm">
                                                        <li id="odds">{bOdds}</li>
                                                    </ul>
                                                </dd>
                                                <dd _ngcontent-njs-c61="" class="col-stake">

                                                    <input _ngcontent-njs-c61="" id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                </dd>
                                                <dd _ngcontent-njs-c61="" class="col-send">
                                                    <a _ngcontent-njs-c61="" onClick={(e) => { setbookBetSelection(1); setTimeout(() => { setbookBetSelection(0); }, 5000); placeBet(); closeBookBet(); }} id="placeBet" class="btn-send" style={{ cursor: 'pointer' }}>Place Bets
                                                    </a>
                                                </dd>
                                                <dd _ngcontent-njs-c61="" id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} bookmakerbetboard="bookMakerBetBoard_30045511_40466_119397_1">
                                                    <ul>
                                                        <li _ngcontent-njs-c61=""  ><a class="btn" onClick={() => { setdefaultStake(ssstake1); }} id="selectStake_1" stake="700" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a class="btn" onClick={() => { setdefaultStake(ssstake2); }} id="selectStake_2" stake="700" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a class="btn" onClick={() => { setdefaultStake(ssstake3); }} id="selectStake_3" stake="1000" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a class="btn" onClick={() => { setdefaultStake(ssstake4); }} id="selectStake_4" stake="1000" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a class="btn" onClick={() => { setdefaultStake(ssstake5); }} id="selectStake_5" stake="5000" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a class="btn" onClick={() => { setdefaultStake(ssstake6); }} id="selectStake_6" stake="10000" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </td>
                                    </tr>}


                                {(bookBetSelection === 1 || (props.oneclick === true && window.book_index === 1)) && <Countdown t={5} />}

                                {bookMsg.number && bookMsg.number === 1 && bookMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td _ngcontent-njs-c61="" colspan="7">
                                        <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                            <dd _ngcontent-njs-c61="" id="info">
                                                <strong _ngcontent-njs-c61="" id="header">Bet Matched</strong>
                                                USD at odds: {props.oneclick === false ? bOdds : window.book_odd}
                                                <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}
                                {bookMsg.number && bookMsg.number === 1 && bookMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td _ngcontent-njs-c61="" colspan="7">
                                        <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                            <dd _ngcontent-njs-c61="" id="info">
                                                <strong _ngcontent-njs-c61="" id="header">{bookMsg.msg}</strong>

                                                <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}

                                {((bookData.gameStatus != 2) || bookData.runner2Status == 2) && <tr id="bookMakerSuspend_30035305_37909_111799" class="fancy-suspend-tr" marketid="37909">
                                    <th _ngcontent-njs-c61="" colspan=""></th>
                                    <td _ngcontent-njs-c61="" class="fancy-suspend-td" colspan="6">
                                        <div _ngcontent-njs-c61="" id="suspendClass" class="fancy-suspend" ><span id="info">{bookData.gameStatus == 10 && bookData.runner2Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                    </td>
                                </tr>}

                                <tr _ngcontent-njs-c61="" id="bookMakerSelection_30035305_37909_111799" style={{ display: 'table-row' }}>
                                    <th _ngcontent-njs-c61="" class="predict">
                                        <p _ngcontent-njs-c61="" id="runnerName">{marketData.runnerName2}</p>

                                        {pnlBook.teamB && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <span id="before" className={`${pnlBook.teamB > 0 ? "win" : "lose"}`} >{pnlBook.teamB > 0 ? pnlBook.teamB : '(' + Math.abs(pnlBook.teamB) + ')'}</span>}

                                        {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <span id="after" className={`${pnlBook2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamB > 0 ? pnlBook2.teamB : '(' + Math.abs(pnlBook2.teamB) + ')'}</span>}

                                    </th>
                                    <td _ngcontent-njs-c61="" class="" colspan="3">
                                        <dl _ngcontent-njs-c61="" class="back-gradient">
                                            <dd _ngcontent-njs-c61="" id="back_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2BackRate3 === ' ') return; handleBookBets(3, 2, bookData.runner2BackRate3) }} style={{ cursor: 'pointer' }}> <p>{bookData.runner2BackRate3}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="back_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2BackRate2 === ' ') return; handleBookBets(3, 2, bookData.runner2BackRate2) }} style={{ cursor: 'pointer' }}> <p>{bookData.runner2BackRate2}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2BackRate1 === ' ') return; handleBookBets(3, 2, bookData.runner2BackRate1) }} className={`${(selectBack === 3) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner2BackRate1}</a></dd>
                                        </dl>
                                    </td>
                                    <td _ngcontent-njs-c61="" class="" colspan="3">
                                        <dl _ngcontent-njs-c61="" class="lay-gradient">
                                            <dd _ngcontent-njs-c61="" id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2LayRate1 === ' ') return; handleBookBets(4, 2, bookData.runner2LayRate1) }} className={`${(selectBack === 4) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner2LayRate1}</a></dd>
                                            <dd _ngcontent-njs-c61="" id="lay_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2LayRate2 === ' ') return; handleBookBets(4, 2, bookData.runner2LayRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.runner2LayRate2}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="lay_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2LayRate3 === ' ') return; handleBookBets(4, 2, bookData.runner2LayRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.runner2LayRate3}</p></dd>
                                        </dl>
                                    </td>
                                </tr>

                                {(Bookclick === true && !(bookData.gameStatus != 2 || bookData.runner2Status == 2)) &&
                                    <tr _ngcontent-njs-c61="" id="bookMakerBetBoard_30045511_40466_119397_1" class="fancy-quick-tr">
                                        <td _ngcontent-njs-c61="" colspan="7">
                                            <dl _ngcontent-njs-c61="" id="classWrap" className={`quick_bet-wrap ${(selectBack === 3) ? "slip-back" : "slip-lay"}`}>
                                                <dt _ngcontent-njs-c61="" id="bookMakerBetHeader">

                                                    <span _ngcontent-njs-c61="" id="bookMakerBetAcceptCheck" class="bet-check">
                                                        <input _ngcontent-njs-c61="" onChange={() => setacceptAnyOdds(!acceptAnyOdds)} id="bookMakerBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                        <label _ngcontent-njs-c61="" for="bookMakerBetAcceptAnyOdds">Accept Any Odds</label>
                                                    </span>

                                                </dt>
                                                <dd _ngcontent-njs-c61="" class="col-btn"><a id="cancel" onClick={() => { closeBookBet(); }} class="btn" style={{ cursor: 'pointer' }}>Cancel
                                                </a></dd>
                                                <dd _ngcontent-njs-c61="" id="oddsHeader" class="col-odd">
                                                    <ul _ngcontent-njs-c61="" class="quick-bet-confirm">
                                                        <li _ngcontent-njs-c61="" id="odds">{bOdds}</li>
                                                    </ul>
                                                </dd>
                                                <dd _ngcontent-njs-c61="" class="col-stake">

                                                    <input _ngcontent-njs-c61="" id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                </dd>
                                                <dd _ngcontent-njs-c61="" class="col-send">
                                                    <a _ngcontent-njs-c61="" id="placeBet" onClick={(e) => { setbookBetSelection(2); setTimeout(() => { setbookBetSelection(0); }, 5000); placeBet(); closeBookBet(); }} class="btn-send" style={{ cursor: 'pointer' }}>Place Bets
                                                    </a>
                                                </dd>
                                                <dd _ngcontent-njs-c61="" id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} bookmakerbetboard="bookMakerBetBoard_30045511_40466_119397_1">
                                                    <ul>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake1); }} id="selectStake_1" stake="700" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake2); }} id="selectStake_2" stake="700" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake3); }} id="selectStake_3" stake="1000" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake4); }} id="selectStake_4" stake="1000" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake5); }} id="selectStake_5" stake="5000" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake6); }} id="selectStake_6" stake="10000" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </td>
                                    </tr>
                                }


                                {(bookBetSelection === 2 || (props.oneclick === true && window.book_index === 2)) && <Countdown t={5} />}

                                {bookMsg.number && bookMsg.number === 2 && bookMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td _ngcontent-njs-c61="" colspan="7">
                                        <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                            <dd _ngcontent-njs-c61="" id="info">
                                                <strong _ngcontent-njs-c61="" id="header">Bet Matched</strong>
                                                USD at odds: {props.oneclick === false ? bOdds : window.book_odd}
                                                <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}
                                {bookMsg.number && bookMsg.number === 2 && bookMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td _ngcontent-njs-c61="" colspan="7">
                                        <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                            <dd _ngcontent-njs-c61="" id="info">
                                                <strong _ngcontent-njs-c61="" id="header">{bookMsg.msg}</strong>

                                                <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}



                                {/*The Draw */}

                                {bookData2.runnerId3 && bookData2.runnerId3 != ' ' && ((bookData.gameStatus != 2) || bookData.runner3Status == 2) && <tr _ngcontent-njs-c61="" id="bookMakerSuspend_30035305_37909_111799" class="fancy-suspend-tr" marketid="37909">
                                    <th _ngcontent-njs-c61="" colspan=""></th>
                                    <td _ngcontent-njs-c61="" class="fancy-suspend-td" colspan="6">
                                        <div _ngcontent-njs-c61="" id="suspendClass" class="fancy-suspend" ><span _ngcontent-njs-c61="" id="info">{bookData.gameStatus == 10 && bookData.runner3Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                    </td>
                                </tr>}

                                {bookData2.runnerId3 && bookData2.runnerId3 != ' ' && <tr _ngcontent-njs-c61="" id="bookMakerSelection_30035305_37909_111799" style={{ display: 'table-row' }}>
                                    <th _ngcontent-njs-c61="" class="predict">
                                        <p _ngcontent-njs-c61="" id="runnerName">The Draw</p>

                                        {pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <span id="before" className={`${pnlBook.tie > 0 ? "win" : "lose"}`} >{pnlBook.tie > 0 ? pnlBook.tie : '(' + Math.abs(pnlBook.tie) + ')'}</span>}


                                        {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <span id="after" className={`${pnlBook2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.tie > 0 ? pnlBook2.tie : '(' + Math.abs(pnlBook2.tie) + ')'}</span>}

                                    </th>
                                    <td _ngcontent-njs-c61="" class="" colspan="3">
                                        <dl _ngcontent-njs-c61="" class="back-gradient">
                                            <dd _ngcontent-njs-c61="" id="back_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieBackRate3 === ' ') return; handleBookBets(5, 3, bookData.tieBackRate3) }} style={{ cursor: 'pointer' }}> <p>{bookData.tieBackRate3}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="back_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieBackRate2 === ' ') return; handleBookBets(5, 3, bookData.tieBackRate2) }} style={{ cursor: 'pointer' }}> <p>{bookData.tieBackRate2}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieBackRate1 === ' ') return; handleBookBets(5, 3, bookData.tieBackRate1) }} className={`${(selectBack === 5) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.tieBackRate1}</a></dd>
                                        </dl>
                                    </td>
                                    <td _ngcontent-njs-c61="" class="" colspan="3">
                                        <dl _ngcontent-njs-c61="" class="lay-gradient">
                                            <dd _ngcontent-njs-c61="" id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieLayRate1 === ' ') return; handleBookBets(6, 3, bookData.tieLayRate1) }} className={`${(selectBack === 6) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.tieLayRate1}</a></dd>
                                            <dd _ngcontent-njs-c61="" id="lay_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieLayRate2 === ' ') return; handleBookBets(6, 3, bookData.tieLayRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.tieLayRate2}</p></dd>
                                            <dd _ngcontent-njs-c61="" id="lay_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieLayRate3 === ' ') return; handleBookBets(6, 3, bookData.tieLayRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.tieLayRate3}</p></dd>
                                        </dl>
                                    </td>
                                </tr>}




                                {(Bookclick3 === true && !(bookData.gameStatus != 2 || bookData.runner3Status == 2)) &&
                                    <tr _ngcontent-njs-c61="" id="bookMakerBetBoard_30045511_40466_119397_1" class="fancy-quick-tr">
                                        <td _ngcontent-njs-c61="" colspan="7">
                                            <dl _ngcontent-njs-c61="" id="classWrap" className={`quick_bet-wrap ${(selectBack === 5) ? "slip-back" : "slip-lay"}`}>
                                                <dt _ngcontent-njs-c61="" id="bookMakerBetHeader">

                                                    <span _ngcontent-njs-c61="" id="bookMakerBetAcceptCheck" class="bet-check">
                                                        <input _ngcontent-njs-c61="" onChange={() => setacceptAnyOdds(!acceptAnyOdds)} id="bookMakerBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                        <label _ngcontent-njs-c61="" for="bookMakerBetAcceptAnyOdds">Accept Any Odds</label>
                                                    </span>

                                                </dt>
                                                <dd _ngcontent-njs-c61="" class="col-btn"><a id="cancel" onClick={() => { closeBookBet(); }} class="btn" style={{ cursor: 'pointer' }}>Cancel
                                                </a></dd>
                                                <dd _ngcontent-njs-c61="" id="oddsHeader" class="col-odd">
                                                    <ul _ngcontent-njs-c61="" class="quick-bet-confirm">
                                                        <li _ngcontent-njs-c61="" id="odds">{bOdds}</li>
                                                    </ul>
                                                </dd>
                                                <dd _ngcontent-njs-c61="" class="col-stake">

                                                    <input _ngcontent-njs-c61="" id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                </dd>
                                                <dd _ngcontent-njs-c61="" class="col-send">
                                                    <a _ngcontent-njs-c61="" id="placeBet" onClick={(e) => { setbookBetSelection(3); setTimeout(() => { setbookBetSelection(0); }, 5000); placeBet(); closeBookBet(); }} class="btn-send" style={{ cursor: 'pointer' }}>Place Bets
                                                    </a>
                                                </dd>
                                                <dd _ngcontent-njs-c61="" id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} bookmakerbetboard="bookMakerBetBoard_30045511_40466_119397_1">
                                                    <ul _ngcontent-njs-c61=""  >
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake1); }} id="selectStake_1" stake="700" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake2); }} id="selectStake_2" stake="700" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake3); }} id="selectStake_3" stake="1000" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake4); }} id="selectStake_4" stake="1000" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake5); }} id="selectStake_5" stake="5000" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                        <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" class="btn" onClick={() => { setdefaultStake(ssstake6); }} id="selectStake_6" stake="10000" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </td>
                                    </tr>
                                }


                                {(bookBetSelection === 3 || (props.oneclick === true && window.book_index === 3)) && <Countdown t={5} />}

                                {bookMsg.number && bookMsg.number === 3 && bookMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td _ngcontent-njs-c61="" colspan="7">
                                        <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                            <dd _ngcontent-njs-c61="" id="info">
                                                <strong _ngcontent-njs-c61="" id="header">Bet Matched</strong>
                                                USD at odds: {props.oneclick === false ? bOdds : window.book_odd}
                                                <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}
                                {bookMsg.number && bookMsg.number === 3 && bookMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td _ngcontent-njs-c61="" colspan="7">
                                        <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                            <dd _ngcontent-njs-c61="" id="info">
                                                <strong _ngcontent-njs-c61="" id="header">{bookMsg.msg}</strong>

                                                <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}

                            </tbody>
                        </table>
                    </div>}





                {eventT == 2378962 &&
                    <div id="gameHead" className="game-head" style={{}}>
                        {/* Team Table */}
                        {/* <a class="favor select">Add Favorite</a> */}
                        <a id="refreshButton" className="refresh" style={{ cursor: 'pointer' }}>Refresh</a>
                        <a id="multiMarketPin" className="add-pin" name="gameHeadPin" style={{ cursor: 'pointer', display: 'none' }} title="Add to Multi Markets" eventtype={2378962} eventid={-10432664} marketid={-10432704} />
                        <table id="gameTeam" className="game-team">
                            <tbody>
                                <tr id="noTeam" style={{}}>
                                    <td className="game-name">Karnataka Assembly Election&nbsp;2023<span>Karnataka Assembly Election&nbsp;2023</span></td>
                                </tr>
                                <tr id="twoTeam" style={{ display: 'none' }}>
                                    <td id="gameVisit" className="game-visit">-visit-<span /></td>
                                    <td id="gameVs" className="game-vs">
                                        <div>All Markets<span>VS</span></div>
                                    </td>
                                    <td id="gameHome" className="game-home">-home-<span /></td>
                                </tr>
                            </tbody>
                        </table>
                        <a id="lineMarketRulesBtn" className="btn-rules" style={{ cursor: 'pointer', display: 'none' }}><img src={Transparent} />Rules</a>
                        <div id="lineMarketRulesWrap" className="rules-wrap" style={{ display: 'none' }}>
                            <h3>Line Market Rules and Information</h3>
                            <div className="rules-content">
                                <p>
                                    How many runs would be scored in mentioned overs, If <strong> 'Back/Yes' </strong> bet is made then it will be win bet if the runs scored in the mentioned overs are <strong> equal or more </strong> then the Backed Runs
                                    otherwise it will be a Lost bets. If <strong> 'Lay/No' </strong> bet is made then it will be a win bet if Runs scored in the mentioned overs are <strong> less </strong> than the lay runs otherwise it will be lost bet. All
                                    the line markets will be settled only if the mentioned stipulated overs are completed or if the Batting team is bowled out (All out) or the runs are chased, given the inning's stipulated(or reduced) overs are more than or
                                    equal to the stipulated over of that particular line Market.
                                </p>
                                <p><strong> "Matched Tied" </strong> and <strong> "Completed Match" </strong> doesn't affect the settling of Line markets.</p>
                            </div>
                            <ul className="btn-wrap break">
                                <li><a id="lineMarketRulesOk" className="btn" style={{ cursor: 'pointer' }}>OK</a></li>
                            </ul>
                        </div>
                        <table id="cricketScoreBoard" className="game-scores" style={{ display: 'none' }}>
                            <tbody>
                                <tr>
                                    <td id="gameHeadPinSpace" className="scores-space" />
                                    <td className="scores-team">
                                        <h4 id="teamHome">HOME</h4>
                                        <h4 id="teamAway">AWAY</h4>
                                        <ul className="scores-time">
                                            <li id="description" className="filter-first" />
                                            <li id="currentDay" />
                                        </ul>
                                    </td>
                                    <td id="inningsBox1" className="scores-box" style={{ display: 'none' }}>
                                        <h4 id="runsHome"><span /></h4>
                                        <h4 id="runsAway"><span /></h4>
                                        <span id="inningsIndex" className="scores-inns">Innings 1</span>
                                    </td>
                                    <td id="inningsBoxTemplate" className="scores-box" style={{ display: 'none' }}>
                                        <h4 id="runsHome"><span /></h4>
                                        <h4 id="runsAway"><span /></h4>
                                        <span id="inningsIndex" className="scores-inns" />
                                    </td>
                                    <td className="scores-space" />
                                </tr>
                            </tbody>
                        </table>
                        <table id="tennisScoreBoard" className="game-scores tennis" style={{ display: 'none' }}>
                            <tbody>
                                <tr>
                                    <td id="gameHeadPinSpace" className="scores-space" />
                                    <td className="scores-team">
                                        <h4 id="teamHome">${'{'} Home {'}'}</h4>
                                        <h4 id="teamAway">${'{'} Away {'}'}</h4>
                                        <ul className="scores-time">
                                            <li id="currentSet" className="filter-first" />
                                            <li id="fullTimeElapsed" />
                                            <li><span id="court" /></li>
                                        </ul>
                                    </td>
                                    <td id="setBox1" className="scores-box">
                                        <h4 id="setHome" />
                                        <h4 id="setAway" />
                                        <span className="scores-inns">1</span>
                                    </td>
                                    <td id="setBox2" className="scores-box">
                                        <h4 id="setHome" />
                                        <h4 id="setAway" />
                                        <span className="scores-inns">2</span>
                                    </td>
                                    <td id="setBox3" className="scores-box last-box">
                                        <h4 id="setHome" />
                                        <h4 id="setAway" />
                                        <span className="scores-inns">3</span>
                                    </td>
                                    <td id="points" className="scores-box last-box">
                                        <h4 id="pointsHome" className="scores-now">0</h4>
                                        <h4 id="pointsAway" className="scores-now">0</h4>
                                        <span className="scores-inns">Points</span>
                                    </td>
                                    <td id="aces" className="scores-box scores-other">
                                        <h4 id="acesHome">0</h4>
                                        <h4 id="acesAway">0</h4>
                                        <span className="scores-inns">Aces</span>
                                    </td>
                                    <td id="faults" className="scores-box scores-other">
                                        <h4 id="faultsHome">0</h4>
                                        <h4 id="faultsAway">0</h4>
                                        <span className="scores-inns">Faults</span>
                                    </td>
                                    <td id="breaks" className="scores-box scores-other last-box">
                                        <h4 id="breaksHome">0</h4>
                                        <h4 id="breaksAway">0</h4>
                                        <span className="scores-inns">Breaks</span>
                                    </td>
                                    <td className="scores-space" />
                                    <td id="setBoxTemplate" className="scores-box last-box" style={{ display: 'none' }}>
                                        <h4 id="setHome" />
                                        <h4 id="setAway" />
                                        <span id="setIndex" className="scores-inns" />
                                    </td>
                                    <td className="scores-space" />
                                </tr>
                            </tbody>
                        </table>
                        <div id="soccerScoreBoard" className="game-scores" style={{ display: 'none' }}>
                            <ul>
                                <li className="game-visit"><h4 id="teamHome" /></li>
                                <li className="scores-box">
                                    <p id="vsScores" className="scores-now" />
                                    <span id="timeElapsed" className="scores-now" />
                                </li>
                                <li className="game-home"><h4 id="teamAway" /></li>
                            </ul>
                            <div className="game-progress">
                                <div className="progress-bar">
                                    <div id="progressPercent" className="progress-now" style={{ width: '0%' }} />
                                    <img id="soccerEventTemplate" className style={{ left: '0%', display: 'none' }} src={Transparent} />
                                    <div id="popupTemplate" className="score-pop" style={{ left: '0%', display: 'none' }}>
                                        <ul>
                                            <li id="matchTime" className="scores-now" />
                                            <li><img className src={Transparent} /></li>
                                        </ul>
                                    </div>
                                    <span id="sumTemplate" className="sum-status" style={{ left: '0%', display: 'none' }} />
                                    <div id="sumPopupTemplate" className="score-pop-last" style={{ left: '0%', display: 'none' }}>
                                        <ul id="list">
                                            <li id="matchTimeTemplate" className="scores-now" style={{ display: 'none' }} />
                                            <li id="iconTemplate" style={{ display: 'none' }}><img className src={Transparent} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table id="basketballScoreBoard" className="game-scores tennis" style={{ display: 'none' }}>
                            <tbody>
                                <tr>
                                    <td className="scores-space" />
                                    <td className="scores-team">
                                        <h4 id="teamHome">HOME</h4>
                                        <h4 id="teamAway">AWAY</h4>
                                        <ul className="scores-time">
                                            <li className="filter-first" id="matchStatus" />
                                            <li id="timeElapsed" />
                                        </ul>
                                    </td>
                                    <td id="period1" className="scores-box">
                                        <h4 id="homeScore">-</h4>
                                        <h4 id="awayScore">-</h4>
                                        <span className="scores-inns">1</span>
                                    </td>
                                    <td id="period2" className="scores-box">
                                        <h4 id="homeScore">-</h4>
                                        <h4 id="awayScore">-</h4>
                                        <span className="scores-inns">2</span>
                                    </td>
                                    <td id="period3" className="scores-box">
                                        <h4 id="homeScore">-</h4>
                                        <h4 id="awayScore">-</h4>
                                        <span className="scores-inns">3</span>
                                    </td>
                                    <td id="period4" className="scores-box last-box">
                                        <h4 className="scores-now" id="homeScore">-</h4>
                                        <h4 className="scores-now" id="awayScore">-</h4>
                                        <span className="scores-inns">4</span>
                                    </td>
                                    <td id="totalScore" className="scores-box last-box">
                                        <h4 className="scores-now" id="homeScore">-</h4>
                                        <h4 className="scores-now" id="awayScore">-</h4>
                                        <span className="scores-inns">Points</span>
                                    </td>
                                    <td className="scores-space" />
                                </tr>
                            </tbody>
                        </table>
                        {/* Scores Table finished */}
                        <div id="scoresEvent" className="scores-event" style={{ display: 'none' }}><img src={Transparent} /></div>
                        {/* Tab Market */}
                        <ul id="tabMarketList" className="tab-HS" style={{ display: 'none' }} />
                        <li id="tabMarketTemplate" style={{ display: 'none' }}><a id="tabName" className style={{ cursor: 'pointer' }}>Win</a></li>
                        <div className="game-info-wrap">
                            {/* Game Information */}
                            <ul className="game-info">
                                <li id="infoLive" className="info-live" style={{ display: 'none' }}>
                                    <a className="btn-live"><img className="icon-live" src={Transparent} />Live</a>
                                </li>
                                <li id="gameInfoDate" className="green"><img className="icon-irun" src={Transparent} /> In-Play</li>
                                {/* <li><img class="icon-live" src="/images/transparent.gif" />Live</li> */}
                                {/* <li><img class="icon-irun" src="/images/transparent.gif" />In-Play</li> */}
                                {/* <li><img class="icon-cashout" src="/images/transparent.gif" />Cash Out</li> */}
                                {/* Low Liquidity Tag */}
                            </ul>
                            <span id="lowLiquidityTag" className="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>
                            <dl id="minMaxBox" className="fancy-info" style={{ display: 'none' }}>
                                <dt id="minMaxDt" style={{ display: 'none' }}>Min/Max</dt>
                                <dt id="maxDt" style={{ display: 'none' }}>Max</dt>
                                <dd id="minMaxInfo">0 / 0</dd>
                            </dl>
                            <div id="fancyBetRulesWrap" className="rules-wrap" style={{ display: 'none' }}>
                                <h3 id="fancyBetHeader" style={{ display: 'none' }}>Rules of Fancy Bets</h3>
                                <h3 id="electionHeader" style={{}}>Rules of Election Fancy Bets</h3>
                                <div className="rules-content">
                                    <ol id="fancyBetRules" name="fancyBetRules" style={{ display: 'none' }}>
                                        <li>
                                            Once all session/fancy bets are completed and settled there will be no reversal even if the Match is Tied or is Abandoned.
                                            <ul>
                                                1.2 Middle session and Session is not completed due to Innings declared or all out so that particular over considered as completed and remaining over counted in next team Innings for ex:- In case of Innings
                                                declared or all out In 131.5th over Considered as 132 over completed remaining 1 over counted for 133 over middle session and 3 over counted for 135 over session from next team Innings and One over session and
                                                Only over session is not completed due to innings declared so that Particular over session bets will be deleted and all out considered as valid for ex:- In case of Innings declared In 131.5th over so 132 over
                                                will be deleted and if all out then 132 over and Only 132 over will be Valid.
                                            </ul>
                                        </li>
                                        <li>
                                            Advance Session or Player Runs and all Fancy Bets are only valid for 20/50 overs full match each side. (Please Note this condition is applied only in case of Advance Fancy Bets only).
                                            <ul>
                                                • Adv Session Markets is Valid Only for First Innings of the Match
                                            </ul>
                                        </li>
                                        <li>All advance fancy bets market will be suspended 60 mins prior to match and will be settled.</li>
                                        <li>Under the rules of Session/Fancy Bets if a market gets Suspended for any reason whatsoever and does not resume then all previous Bets will remain Valid and become HAAR/JEET bets.</li>
                                        <li>Incomplete Session/Fancy Bet will be cancelled but Complete Session will be settled.</li>
                                        <li>
                                            In the case of Running Match getting Cancelled/ No Result/ Abandoned but the session is complete it will still be settled. Player runs / fall of wicket will be also settled at the figures where match gets stopped due
                                            to rain for the inning (D/L) , cancelled , abandoned , no result.
                                        </li>
                                        <li>If a player gets Retired Hurt and one ball is completed after you place your bets then all the betting till then is and will remain valid. We Consider Retired Out as Retired Hurt</li>
                                        <li>Should a Technical Glitch in Software occur, we will not be held responsible for any losses.</li>
                                        <li>Should there be a power failure or a problem with the Internet connection at our end and session/fancy market does not get suspended then our decision on the outcome is final.</li>
                                        <li>All decisions relating to settlement of wrong market being offered will be taken by management. Management will consider all actual facts and decision taken will be full in final.</li>
                                        <li>
                                            Any bets which are deemed of being suspicious, including bets which have been placed from the stadium or from a source at the stadium maybe voided at anytime. The decision of whether to void the particular bet in
                                            question or to void the entire market will remain at the discretion of Company. The final decision of whether bets are suspicious will be taken by Company and that decision will be full and final.
                                        </li>
                                        <li>
                                            Any sort of cheating bet , any sort of Matching (Passing of funds), Court Siding (Ghaobaazi on commentary), Sharpening, Commission making is not allowed in Company, If any company User is caught in any of such act
                                            then all the funds belonging that account would be seized and confiscated. No argument or claim in that context would be entertained and the decision made by company management will stand as final authority.
                                        </li>
                                        <li>
                                            Fluke hunting/Seeking is prohibited in Company , All the fluke bets will be reversed. Cricket commentary is just an additional feature and facility for company user but company is not responsible for any delay or
                                            mistake in commentary.
                                        </li>
                                        <li>
                                            Valid for only 1st inning.
                                            <ul>
                                                • Highest Inning Run :- This fancy is valid only for first inning of the match.
                                            </ul>
                                            <ul>
                                                • Lowest Inning Run :- This fancy is valid only for first inning of the match.
                                            </ul>
                                        </li>
                                        <li>
                                            If any fancy value gets passed, we will settle that market after that match gets over. For example :- If any market value is ( 22-24 ) and incase the result is 23 than that market will be continued, but if the result
                                            is 24 or above then we will settle that market. This rule is for the following market.
                                            <ul>
                                                • Total Sixes In Single Match
                                            </ul>
                                            <ul>
                                                • Total Fours In Single Match
                                            </ul>
                                            <ul>
                                                • Highest Inning Run
                                            </ul>
                                            <ul>
                                                • Highest Over Run In Single Match
                                            </ul>
                                            <ul>
                                                • Highest Individual Score By Batsman
                                            </ul>
                                            <ul>
                                                • Highest Individual Wickets By Bowler
                                            </ul>
                                        </li>
                                        <li>
                                            If any fancy value gets passed, we will settle that market after that match gets over. For example :- If any market value is ( 22-24 ) and incase the result is 23 than that market will be continued, but if the result
                                            is 22 or below then we will settle that market. This rule is for the following market.
                                            <ul>
                                                • Lowest Inning Run
                                            </ul>
                                            <ul>
                                                • Fastest Fifty
                                            </ul>
                                            <ul>
                                                • Fastest Century
                                            </ul>
                                        </li>
                                        <li>If any case wrong rate has been given in fancy ,that particular bets will be cancelled (Wrong Commentary).</li>
                                        <li>In case customer make bets in wrong fancy we are not liable to delete, no changes will be made and bets will be considered as confirm bet.</li>
                                        <li>
                                            Dot Ball Market Rules
                                            <ul>
                                                Wides Ball - Not Count
                                            </ul>
                                            <ul>
                                                No Ball - Not Count
                                            </ul>
                                            <ul>
                                                Leg Bye - Not Count as A Dot Ball
                                            </ul>
                                            <ul>
                                                Bye Run - Not Count as A Dot Ball
                                            </ul>
                                            <ul>
                                                Run Out - On 1st Run Count as A Dot Ball
                                            </ul>
                                            <ul>
                                                Run Out - On 2nd n 3rd Run Not Count as a Dot Ball
                                            </ul>
                                            <ul>
                                                Out - Catch Out, Bowled, Stumped n LBW Count as A Dot Ball
                                            </ul>
                                        </li>
                                        <li>
                                            Bookmaker Rules
                                            <ul>
                                                • Due to any reason any team will be getting advantage or disadvantage we are not concerned.
                                            </ul>
                                            <ul>
                                                • We will simply compare both teams 25 overs score higher score team will be declared winner in ODI.
                                            </ul>
                                            <ul>
                                                • We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches.
                                            </ul>
                                        </li>
                                        <li>Penalty Runs - Any Penalty Runs Awarded in the Match (In Any Running Fancy or ADV Fancy) Will Not be Counted While Settling in our Exchange.</li>
                                        <li>LIVE STREAMING OF ALL VIRTUAL CRICKET MATCHES IS AVAILABLE HERE https://www.youtube.com/channel/UCd837ZyyiO5KAPDXibynq_Q/featured</li>
                                        <li>CHECK SCORE OF VIRTUAL CRICKET ON https://sportcenter.sir.sportradar.com/simulated-reality/cricket</li>
                                        <li>
                                            Comparison Market
                                            <ul>
                                                In Comparison Market We Don't Consider Tie or Equal Runs on Both the Innings While Settling . Second Batting Team Must need to Surpass 1st Batting's team Total to win otherwise on Equal Score or Below We declare
                                                1st Batting Team as Winner .
                                            </ul>
                                        </li>
                                        <li>Player Boundaries Fancy:- We will only consider Direct Fours and Sixes hit by BAT.</li>
                                        <li>
                                            BOWLER RUN SESSION RULE :-
                                            <ul>
                                                IF BOWLER BOWL 1.1 OVER,THEN VALID ( FOR BOWLER 2 OVER RUNS SESSION )
                                            </ul>
                                            <ul>
                                                IF BOWLER BOWL 2.1 OVER,THEN VALID ( FOR BOWLER 3 OVER RUNS SESSION )
                                            </ul>
                                            <ul>
                                                IF BOWLER BOWL 3.1 OVER,THEN VALID ( FOR BOWLER 4 OVER RUNS SESSION )
                                            </ul>
                                            <ul>
                                                IF BOWLER BOWL 4.1 OVER,THEN VALID ( FOR BOWLER 5 OVER RUNS SESSION )
                                            </ul>
                                            <ul>
                                                IF BOWLER BOWL 9.1 OVER,THEN VALID ( FOR BOWLER 10 OVER RUNS SESSION )
                                            </ul>
                                        </li>
                                        <li>
                                            Total Match Playing Over ADV :- We Will Settle this Market after Whole Match gets Completed
                                            <ul>
                                                Criteria :- We Will Count Only Round- Off Over For Both the Innings While Settling (For Ex :- If 1st Batting team gets all out at 17.3 , 18.4 or 19.5 we Will Count Such Overs as 17, 18 and 19 Respectively and if
                                                Match gets Ended at 17.2 , 18.3 or 19.3 Overs then we will Count that as 17 , 18 and 19 Over Respectively... and this Will Remain Same For Both the Innings ..
                                            </ul>
                                            <ul>
                                                In Case Of Rain or if Over gets Reduced then this Market will get Voided
                                            </ul>
                                        </li>
                                        <li>
                                            3 WKT OR MORE BY BOWLER IN MATCH ADV :-
                                            <ul>
                                                We Will Settle this Market after Whole Match gets Completed .
                                            </ul>
                                            <ul>
                                                In Case Of Rain or if Over Gets Reduced then this Market Will get Voided
                                            </ul>
                                        </li>
                                        <li>
                                            KHADDA :-
                                            <ul>
                                                ADV Khadda Fancy is Valid Only for First Innings of the Match
                                            </ul>
                                            <ul>
                                                In Case of Rain or If Over Gets Reduced then this Market Will get Voided
                                            </ul>
                                            <ul>
                                                Incomplete Session Bet will be Cancelled but Complete Session Will be Settled
                                            </ul>
                                        </li>
                                        <li>
                                            LOTTERY :-
                                            <ul>
                                                In Case of Rain or If Over Gets Reduced then this Market Will get Voided
                                            </ul>
                                            <ul>
                                                Incomplete Session Bet will be Cancelled but Complete Session Will be Settled
                                            </ul>
                                            <ul>
                                                Criteria :- We will Only Count Last Digit of Sessions Total while settling ..For Example if in 6 Overs Market the Score is 37 ...so we will Settle the Market for 6 Over Lottery @ 7
                                            </ul>
                                        </li>
                                        <li>
                                            Any cricket event which is being held behind closed doors in that if any players found to be taking advantage of groundline in fancy bets in such cases bets can be voided after the market ends . Company decision to
                                            be final .
                                        </li>
                                        <li>
                                            IPL 2021 RULES :-
                                            <ul>
                                                If Over Reduced in Match, we will not count the actual scores of the Over Reduced Matches instead we will count the Market’s Average Scores.
                                            </ul>
                                            <ul>
                                                If Match is Abandoned, we will not count the actual scores of the Abandoned Matches instead we will count the Market’s Average Scores.
                                            </ul>
                                            <ul>
                                                NOTE: -. These rules are for the following Markets of ENTIRE IPL 2021 {'{'}60 Matches{'}'}: -
                                            </ul>
                                            <ul>
                                                Total Match 1st Over Run :- Average 6 Runs will be given if the match is abandoned or over reduced, But If 1st Over is Completed then it will not be counted as Average Score instead we will count the Actual Score
                                                if Over is Reduced after completion of 1st Over.
                                            </ul>
                                            <ul>
                                                Total Fours: - Average 26 Fours will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Sixes: - Average 13 Sixes will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Wide: - Average 8 Wide’s will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Extras: - Average 14 Extras will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total No Ball: - Average 1 No Ball will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Duck: - Average 1 Duck will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Fifties: - Average 2 Fifties will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Century: -Average 0 Century will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Run Out: - Average 1 Run Out will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Wickets: - Average 12 Wickets will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Caught out: - Average 8 Caught Out will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Maiden Over: - Average 0 Maiden Over will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total LBW: - Average 1 LBW will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                Total Bowled: - Average 2 Bowled will be given if the match is abandoned or over reduced.
                                            </ul>
                                            <ul>
                                                • In case IPL Matches Gets Stopped or Interrupted Due to "COVID-19" or Any "ACT OF GOD" Reasons, Under 45 Matches then All IPL 2021 Tournament Fancy Markets will be Voided, But if 45 or Above Matches are Being
                                                Played then we will Settle all the Fancy Markets and Also If there are NON Played Matches Above 45 then we will settle the markets as per our Markets Average.
                                            </ul>
                                            <ul>
                                                * If IPL 2021 gets stopped due to “Covid-19” or Any “Act of God” reason then We will wait till 45 days from the day IPL gets stopped , and if IPL Matches gets Resumed before 45th day then we will continue as
                                                Usual Score Countation but if IPL 2021 does not resumes until 45th day then we will settle the markets according to our above mentioned Markets Averages and In case of Void, We will Void the under 45 matches on
                                                45th day from the day IPL 2021 stopped.
                                            </ul>
                                        </li>
                                        <li>
                                            Session Odd-Even Rule:-
                                            <ul>
                                                We Will Settle the Session ODD-EVEN Market only if the Over is Completed, But If that Over is Not Completed then we will Void that “Session Total Odd” Market.
                                            </ul>
                                        </li>
                                        <li>
                                            Company reserves the right to void any bets (only winning bets) of any event at any point of the match if the company believes there is any cheating/wrong doing in that particular event by the players (either
                                            batsman/bowler)
                                        </li>
                                    </ol>
                                    <ol id="electionRules" style={{}}>
                                        <li>Election result declared by Election Commission of India will be valid in our Exchange.</li>
                                        <li>Accidental issues in Election will Not Be counted in our Exchange.</li>
                                    </ol>
                                </div>
                                <ul className="btn-wrap break">
                                    <li><a className="btn" onclick="javascript: $j('[id^=fancyBetRulesWrap]').fadeOut();">OK</a></li>
                                </ul>
                            </div>
                            <div id="sportsBookRulesWrap_1" className="rules-wrap" style={{ display: 'none' }}>
                                <h3 id="sportsbookRulesTitle">Rules of Premium Tennis</h3>
                                <div className="rules-content">
                                    <ol id="owTennisRulesContent" style={{ display: 'none' }}>
                                        <li>
                                            Handicap
                                            <ul>
                                                <li>Set Handicap betting are settle based on the Sets</li>
                                                <li>
                                                    For Tennis Set hdp betting, the player you wager on must cover/beat the set Spread. This means that the favored player must win by more than the outlined number of sets or the underdog will receive that
                                                    number of sets as a head start. In the above exmaple, If you wager on Andreas Seppi +1.5, he would need to win the match by any score or alternately lose by 1 set. If you wager on Daniil Medvedec -1.5, he
                                                    would need to win by 2 set .
                                                </li>
                                                <li>If the scheduled number of sets required to win a match is changed from that originally scheduled then all bets will be void, except for those that have been unconditionally determined.</li>
                                                <li>All bets will be consider void if the match is not completed.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Over/Under
                                            <ul>
                                                <li>Game Total settle based on the total number of Games played of the match.</li>
                                                <li>All bets will be consider void if the match is not completed.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Money Line
                                            <ul>
                                                <li>Money line markets refer to the winner of the match or specified set.</li>
                                                <li>If a player does not start a tournament or match , disqualified, retires during the match, then all bets on that player will be void.</li>
                                                <li>If a match is postponed or suspended then all bets are still considered valid if the match is completed.</li>
                                                <li>All bets will still be considered valid regardless of any change of venue or court surface (including moving matches from outdoor to indoor courts or vice versa).</li>
                                                <li>All bets will be consider void if the match is not completed.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Game Handicap (Tennis)
                                            <ul>
                                                <li>Game Hdandicap betting are settled based on Games.</li>
                                                <li>
                                                    For Tennis Game Hdp betting , the player you wager on must cover/beat the game spread. This means that the favored player must win by more than the outlined number of games or the underdog will receive that
                                                    number of games as a head start. In the above example, if you wager on Andreas Seppi +3.5, he would need to win the match by any score or alternately lose by 3 games or less. If you wager on Daniil Medvedev
                                                    -3.5, he would need to win by 4 games or more.
                                                </li>
                                                <li>All bets will be consider void if the match is not completed.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Set x Winner (Tennis)
                                            <ul>
                                                <li>Set X Winner refers to the result of the specified set. All bets will be considered void if the specified set is not completed.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Set x Total Game O/U (Tennis)
                                            <ul>
                                                <li>Set X Total Game O/U refers to total Game of the specified set. All bets will be considered void if the specified set is not completed.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <ol id="owSoccerRulesContent" style={{ display: 'none' }}>
                                        <li>
                                            FT.1X2:1X2 means betting in any one of three possible winning outcomes for an Event. 1 refers to the team that is named first (usually the home team); X refers to the game resulting in a draw or tie; 2 refers to the
                                            team that is named second (usually the away team).
                                        </li>
                                        <li>
                                            1H 1X2:1st Half 1X2 means betting in any one of three possible winning outcomes of the first half for an Event. 1 refers to the team that is named first (usually the home team); X refers to the game resulting in a
                                            draw or tie; 2 refers to the team that is named second (usually the away team).
                                        </li>
                                        <li>
                                            Over/Under: Over/Under means betting that is determined by the total number of points (goals, games etc) in the final result of an Event. If the total is more than the Over/Under pre-designated line then the winning
                                            result is Over; if the total is less than the Over/Under pre-designated line then the winning result is Under.
                                        </li>
                                        <li>
                                            1H Over/Under: 1st Half Over/Under means betting that is determined by the total number of points in the result of the first half of an Event. If the total is more than the Over/Under pre-designated line then the
                                            winning result is Over; if the total is less than the Over/Under pre-designated line then the winning result is Under.
                                        </li>
                                        <li>
                                            Over/Under Guideline:
                                            <ul style={{ listStyleType: 'lower-roman' }}>
                                                <li>
                                                    Goal line under 2
                                                    <div>
                                                        Bets win if there is either 0 or 1 goal scored in the match. If there are two goals exactly then the stake is returned. Bets lose if there are three or more goals scored in the match.
                                                    </div>
                                                </li>
                                                <li>
                                                    Goal line over 2
                                                    <div>
                                                        Bets win if there are three or more goals scored in the match. If there are two goals exactly the stake is returned. Bets lose if there is 0 or 1 goal scored in the match.
                                                    </div>
                                                </li>
                                                <li>
                                                    Goal line under 2.25
                                                    <div>
                                                        Bets win if there is either 0 or 1 goal scored in the match. If there are two goals exactly half the stake will win and half will be returned. Bets lose if there are three or more goals scored in the
                                                        match.
                                                    </div>
                                                </li>
                                                <li>
                                                    Goal line over 2.25
                                                    <div>
                                                        Bets win if there are three or more goals scored in the match. If there are two goals exactly half the stake will be returned and half will be lost. Bets lose if there is 0 or 1 goal scored in the match.
                                                    </div>
                                                </li>
                                                <li>
                                                    Goal line under 2.5
                                                    <div>
                                                        Bets win if there are 0, 1 or 2 goals scored in the match. Bets lose if there are three or more goals scored in the match.
                                                    </div>
                                                </li>
                                                <li>
                                                    Goal line over 2.5
                                                    <div>
                                                        Bets win if there are three or more goals scored in the match. Bets lose if there are 0, 1 or 2 goals scored in the match.
                                                    </div>
                                                </li>
                                                <li>
                                                    Goal line under 2.75
                                                    <div>
                                                        Bets win if there are 0, 1 or 2 goals scored in the match. If there are three goals exactly half the stake will be returned and half will be lost. Bets lose if there are four or more goals scored in the
                                                        match.
                                                    </div>
                                                </li>
                                                <li>
                                                    Goal line over 2.75
                                                    <div>
                                                        Bets win if there are four or more goals scored in the match. If there are three goals exactly half the stake will win and half will be returned. Bets lose if there are 0, 1 or 2 goals scored in the
                                                        match.
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Double Chance: The following options are available:
                                            <ul>
                                                <li>1 or X - If the result is either a home or draw then bets on this option are winners.</li>
                                                <li>X or 2 - If the result is either a draw or away then bets on this option are winners.</li>
                                                <li>1 or 2 - If the result is either a home or away then bets on this option are winners.</li>
                                                <li>If a match is played at a neutral venue the team listed first is deemed the home team for betting purposes.</li>
                                            </ul>
                                        </li>
                                        <li>Odd/Even: Odd/Even means betting that is determined by whether the total number of points (goals, games etc) in the final result of an Event is Odd or Even.</li>
                                        <li>
                                            1H Odd/Even: 1st Half Odd/Even means betting that is determined by whether the total number of points at the end of the first half of an Event is Odd or Even. 1st half bets will be void if an Event is abandoned
                                            before the end of the first half. But if an Event is abandoned after the end of the first half, all 1st half bets will be valid.
                                        </li>
                                        <li>
                                            Handicap: Handicap means betting when one competitor or team receives a virtual head start (effectively leading by that head start before the Event begins). The winner is the competitor or team with the better score
                                            after adding the given handicap to the result. Remaining Handicap rules are set out in the Specific Event Betting Rules.
                                        </li>
                                        <li>1H Handicap: 1st Half Handicap means betting on the team with the better score after adding the given handicap to the result of the first half of an Event.</li>
                                        <li>
                                            Correct Score (AOS) :
                                            <ul>
                                                <li>Correct Score means betting to predict the final score at the end of full-time.</li>
                                                <li>First Half Correct Score means betting to predict the final score at the end of First Half.</li>
                                                <li>Correct Score to win "AOS" means the selected team must win to a score not listed in the selection.</li>
                                                <li>
                                                    Example:
                                                    <ul>
                                                        <li>Result for 5-0UP - 5:1, 6:2 and etc (Lose) / 5:0, 6:1 and etc (Win)</li>
                                                        <li>Result for 0-5UP - 1:5, 2:6 and etc (Lose) / 0:5, 1:6 and etc (Win)</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            E-Soccer bets whenever applicable, are governed by the same rules for bets placed on real sporting events. The Company provides information (e.g. neutral ground, Red card, timer, statistical information, dates,
                                            commencement, etc) as a service and accepts no liability whatsoever. The timer and red card notices are given for reference purposes only.
                                        </li>
                                        <li>In case there is a technical issue or malfunction in a match, all placed bets will be voided and refunded in accordance with our general betting rules and regulations.</li>
                                        <li>The Company reserves its right to void any bet when it deems necessary under the circumstances. The decision of the Company with respect to any bet is final.</li>
                                    </ol>
                                </div>
                                <ul className="btn-wrap break">
                                    <li><a onclick="$j('[id^=sportsBookRulesWrap_1]').fadeOut()" className="btn" href="#">OK</a></li>
                                </ul>
                            </div>
                            <a id="openStreaming" className="open-tv-wrap" style={{ display: 'none' }}>Live</a>
                            <dl className="game-matched">
                                <dt id="gameMatchedName" style={{ display: 'none' }}>Matched</dt>
                                <dd id="gameMatched" style={{ display: 'none' }}>PHK 0</dd>
                            </dl>
                        </div>
                    </div>
                }

                {eventT == 4 && <div _ngcontent-njs-c61="" id="fancyBetTable_30035305" class="bets-wrap fancy_bet" name="multiMarketItem">


                    <div _ngcontent-njs-c61="" id="fancyBetHead" class="fancy-head">
                        <h4 _ngcontent-njs-c61="" class="in-play">
                            {/*<!-- a id="multiMarketPin" class="add-favourite" name="te" style={{cursor:'pointer'}} title="Add to Multi Markets"></a-->*/}
                            <span _ngcontent-njs-c61="" id="headerName">{eventT === 4 ? "Fancy Bet" : " Election Fancy Bet"}</span>


                            <a _ngcontent-njs-c61="" class="btn-fancybet_rules" ></a>

                        </h4>

                    </div>



                    <table _ngcontent-njs-c61="" id="fancyBetMarketList" class="fm-bets" >
                        <colgroup>
                            <col span="1" width="280" />
                            <col span="1" width="70" />
                            <col span="1" width="70" />
                            <col span="1" width="70" />
                            <col span="1" width="70" />
                            <col span="1" width="70" />
                            <col span="1" width="70" />
                        </colgroup>
                        <tbody _ngcontent-njs-c61=""  >
                            <tr _ngcontent-njs-c61="" id="fancyBetSpecialBet" class="special_bet">
                                <td _ngcontent-njs-c61="" colspan="7">
                                    <h3 _ngcontent-njs-c61=""  >
                                        <a _ngcontent-njs-c61="" id="multiMarketPin" class="add-favourite" title="Add to Multi Markets">Add Pin</a>
                                        Fancy Bet
                                    </h3>
                                </td>
                            </tr>
                            <tr _ngcontent-njs-c61="" class="fm-bet-zone">
                                <td></td>
                                <td _ngcontent-njs-c61="" class="refer-bet" colspan="2"></td>
                                <td _ngcontent-njs-c61=""  >No</td>
                                <td _ngcontent-njs-c61=""  >Yes</td>
                                <td _ngcontent-njs-c61="" class="refer-book" colspan="2"></td>
                            </tr>







                            {fancyData.map(function (item, index) {


                                var pnl = null;
                                var pnl2 = null;


                                if (pnlFancy[item.selectionId]) {
                                    pnl = parseFloat(pnlFancy[item.selectionId]).toFixed(2);
                                }
                                if (click === index) {

                                    if (field === 1 && defaultStake != ' ' && defaultStake != '') {
                                        pnl2 = -(parseFloat(fsize / 100) * parseFloat(defaultStake)).toFixed(2);
                                    }
                                    else if (field === 2 && defaultStake != ' ' && defaultStake != '') {
                                        pnl2 = -Math.abs(defaultStake);
                                    }

                                }


                                return (
                                    <React.Fragment key={index}>

                                        {(item.gameStatus != 2) && <tr _ngcontent-njs-c61="" id="bookMakerSuspend_30035305_37909_111799" class="fancy-suspend-tr" marketid="37909">
                                            <th _ngcontent-njs-c61="" colspan="3"></th>
                                            <td _ngcontent-njs-c61="" class="fancy-suspend-td" colspan="2">
                                                <div _ngcontent-njs-c61="" id="suspendClass" class="fancy-suspend" ><span id="info">{item.gameStatus == 10 ? 'Ball Running' : 'Suspend'}</span></div>
                                            </td>
                                        </tr>}

                                        <tr _ngcontent-njs-c61="" id="fancyBetMarket_168674" >
                                            <th _ngcontent-njs-c61="" colspan="3">
                                                <dl _ngcontent-njs-c61="" class="fancy-th-layout">
                                                    <dt _ngcontent-njs-c61=""  >
                                                        <p _ngcontent-njs-c61="" id="marketName">{item.runnerName}</p>

                                                        {pnl && <span _ngcontent-njs-c61="" id="before" className={`${pnl >= 0 ? "win" : "lose"}`}>{pnl >= 0 ? pnl : '(' + Math.abs(pnl) + ')'}</span>}

                                                    </dt>

                                                </dl>
                                            </th>


                                            <td _ngcontent-njs-c61="" id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } OpenfancyBet(index, item.layPrice, item.laySize, item.selectionId); setfOdds(item.layPrice); setfsize(item.laySize); }} className={`lay-1 ${(selectFancy === 1 && click === index && field === 1) ? "select" : "null"}`} >
                                                <a _ngcontent-njs-c61="" id="runsInfo" style={{ cursor: 'pointer' }}>{item.layPrice ? item.layPrice : '--'}<span>{item.laySize ? item.laySize : '--'}</span></a>
                                            </td>
                                            <td _ngcontent-njs-c61="" id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } OpenfancyBetLay(index, item.backPrice, item.backSize, item.selectionId); setfOdds(item.backPrice); setfsize(item.backSize); }} className={`back-1 ${(selectFancyLay === 1 && click === index && field === 2) ? "select" : "null"}`}>
                                                <a _ngcontent-njs-c61="" id="runsInfo" style={{ cursor: 'pointer' }}>{item.backPrice ? item.backPrice : '--'}<span>{item.backSize ? item.backSize : '--'}</span></a>
                                            </td>
                                            <td _ngcontent-njs-c61="" class="td-fancy_merge" colspan="2">
                                                <dl _ngcontent-njs-c61="" class="fancy-info">
                                                    <dt _ngcontent-njs-c61=""  >Min/Max</dt>
                                                    <dd _ngcontent-njs-c61="" id="minMax"> {parseInt(item.minStack) * coinFactor} / {parseInt(item.maxStack) * coinFactor}</dd>
                                                </dl>

                                            </td>
                                        </tr>

                                        {click === index && props.oneclick === false &&

                                            <tr _ngcontent-njs-c61="" id="fancyBetBoard_30042557_170374_2" class="fancy-quick-tr">
                                                <td _ngcontent-njs-c61="" colspan="7">
                                                    <dl _ngcontent-njs-c61="" id="classWrap" className={`quick_bet-wrap ${field === 1 ? "slip-lay" : "slip-back"}`}>
                                                        <dt _ngcontent-njs-c61="" id="fancyBetHeader" class="">

                                                            <span _ngcontent-njs-c61="" id="fancyBetAcceptCheck" class="bet-check">
                                                                <input _ngcontent-njs-c61="" id="fancyBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                                <label _ngcontent-njs-c61="" for="fancyBetAcceptAnyOdds">Accept Any Odds</label>
                                                            </span>

                                                        </dt>
                                                        <dd _ngcontent-njs-c61="" class="col-btn"><a id="cancel" class="btn" onClick={() => { closeFancyBet() }} style={{ cursor: 'pointer' }}>Cancel</a></dd>
                                                        <dd _ngcontent-njs-c61="" id="oddsHeader" class="col-odd">
                                                            <ul _ngcontent-njs-c61="" class="quick-bet-confirm">
                                                                <li _ngcontent-njs-c61="" id="runs">{fOdds}</li>
                                                                <li _ngcontent-njs-c61="" id="odds" class="quick-bet-confirm-title">{fsize}</li>
                                                            </ul>
                                                        </dd>
                                                        <dd _ngcontent-njs-c61="" class="col-stake">
                                                            <input _ngcontent-njs-c61="" id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                        </dd>
                                                        <dd _ngcontent-njs-c61="" class="col-send">
                                                            <a _ngcontent-njs-c61="" id="placeBet" class="btn-send" onClick={(e) => { setfancySelection(index); setTimeout(() => { setfancySelection(-1); }, 3000); fancyBet(item.selectionId, field, index); closeFancyBet(); }} style={{ cursor: 'pointer' }}>Place Bets</a>
                                                        </dd><dd _ngcontent-njs-c61="" id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} fancybetboard="fancyBetBoard_30042557_170374_2">
                                                            <ul>
                                                                <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" onClick={(e) => { setdefaultStake(ssstake1); }} class="btn" id="selectStake_1" stake="30" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                                <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" onClick={(e) => { setdefaultStake(ssstake2); }} class="btn" id="selectStake_2" stake="40" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                                <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" onClick={(e) => { setdefaultStake(ssstake3); }} class="btn" id="selectStake_3" stake="50" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                                <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" onClick={(e) => { setdefaultStake(ssstake4); }} class="btn" id="selectStake_4" stake="60" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                                <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" onClick={(e) => { setdefaultStake(ssstake5); }} class="btn" id="selectStake_5" stake="70" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                                <li _ngcontent-njs-c61=""  ><a _ngcontent-njs-c61="" onClick={(e) => { setdefaultStake(ssstake6); }} class="btn" id="selectStake_6" stake="80" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                            </ul>
                                                        </dd>
                                                    </dl>
                                                </td>
                                            </tr>}

                                        {((fancySelection === index && !props.oneclick) || (props.oneclick && window.fancy_index === index)) && <Countdown t={3} />}

                                        {(fancyMsg.number || fancyMsg.number === 0) && fancyMsg.number === index && fancyMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                            <td _ngcontent-njs-c61="" colspan="7">
                                                <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                                    <dd _ngcontent-njs-c61="" id="info">
                                                        <strong _ngcontent-njs-c61="" id="header">Bet Matched</strong>
                                                        USD at odds: {fOdds}/{fsize}
                                                        <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>}

                                        {(fancyMsg.number || fancyMsg.number === 0) && fancyMsg.number === index && fancyMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                            <td _ngcontent-njs-c61="" colspan="7">
                                                <dl _ngcontent-njs-c61="" id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                                    <dd _ngcontent-njs-c61="" id="info">
                                                        <strong _ngcontent-njs-c61="" id="header">{fancyMsg.msg}</strong>

                                                        <a _ngcontent-njs-c61="" id="close" class="close-button" style={{ cursor: 'pointer' }}></a>
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>}



                                    </React.Fragment>)
                            })}

                        </tbody>

                    </table>
                </div>}


                <div _ngcontent-njs-c61="" id="fullMarketEventLeft" class="event-left" style={{ display: 'block' }}></div>


                <div _ngcontent-njs-c61="" id="fullMarketEventRight" class="event-right" style={{ display: 'block' }}></div>

            </div>
        </React.Fragment>
    )
}
