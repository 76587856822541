
export const casinoData = [
    {
        "id": 1,
        "imgSrc": "aesexy.jpeg",
        "title": "Sexy",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-001",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 2,
        "imgSrc": "LUDO.webp",
        "title": "LUDO",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "LUDO-TABLE-001",
            "platform": "LUDO"
        }
    },
    {
        "id": 3,
        "imgSrc": "saba-sport.png",
        "title": "SABA",
        "gameInfo": {
            "gameType": "VIRTUAL",
            "gameCode": "SABA-VIRTUAL-001",
            "platform": "SABA"
        }
    },
    {
        "id": 4,
        "imgSrc": "sportse.webp",
        "title": "E1SPORT",
        "gameInfo": {
            "gameType": "ESPORTS",
            "gameCode": "E1-ESPORTS-001",
            "platform": "E1SPORT"
        }
    },
    {
        "id": 5,
        "imgSrc": "",
        "title": "Roulette",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-009",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 6,
        "imgSrc": "",
        "title": "Red Blue Duel",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-010",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 7,
        "imgSrc": "",
        "title": "Teen Patti 2020",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-011",
            "platform": "SEXYBCRT"
        }
    }
    ,
    {
        "id": 8,
        "imgSrc": "",
        "title": "Extra Andar Bahar",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-012",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 9,
        "imgSrc": "",
        "title": "Extra Andar Bahar",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-012",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 10,
        "imgSrc": "",
        "title": "Thai Hi Lo",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-014",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 11,
        "imgSrc": "",
        "title": "Thai Fish Prawn Crab",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-015",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 12,
        "imgSrc": "",
        "title": "Extra Sicbo",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-016",
            "platform": "SEXYBCRT"
        }
    },
    {
        "id": 13,
        "imgSrc": "",
        "title": "Sedie",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-017",
            "platform": "SEXYBCRT",
        }
    },

    {
        "id": 14,
        "imgSrc": "",
        "title": "Sedie",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "MX-LIVE-017",
            "platform": "SEXYBCRT",
        }
    }
    ,
    {
        "id": 15,
        "imgSrc": "",
        "title": "Royal Fishing",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-001",
            "platform": "JILI",
        }
    },
    {
        "id": 16,
        "imgSrc": "",
        "title": "Bombing Fishing",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-002",
            "platform": "JILI",
        }
    },
    {
        "id": 17,
        "imgSrc": "",
        "title": "Jackpot Fishing",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-003",
            "platform": "JILI",
        }
    }

    , {
        "id": 18,
        "imgSrc": "",
        "title": "Dinosaur Tycoon",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-004",
            "platform": "JILI",
        }
    }
    , {
        "id": 19,
        "imgSrc": "",
        "title": "JILI",
        "isLobby": true,
        "gameInfo": {
            "platform": "JILI",
        }
    }

    , {
        "id": 20,
        "imgSrc": "",
        "title": "Dragon Fortune",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-006",
            "platform": "JILI",
        }
    }
    , {
        "id": 21,
        "imgSrc": "",
        "title": "Mega Fishing",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-007",
            "platform": "JILI",
        }
    }
    , {
        "id": 22,
        "imgSrc": "",
        "title": "Boom Legend",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-008",
            "platform": "JILI",
        }
    }
    ,
    {
        "id": 23,
        "imgSrc": "",
        "title": "All-star Fishing",
        "gameInfo": {
            "gameType": "FH",
            "gameCode": "JILI-FISH-008",
            "platform": "JILI",
        }
    }
    ,
    {
        "id": 24,
        "imgSrc": "",
        "title": "Number King",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-005",
            "platform": "JILI",
        }
    }
    ,
    {
        "id": 25,
        "imgSrc": "",
        "title": "Big Small",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-007",
            "platform": "JILI",
        }
    },
    {
        "id": 26,
        "imgSrc": "",
        "title": "TeenPatti 20-20",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-016",
            "platform": "JILI",
        }
    },
    {
        "id": 27,
        "imgSrc": "",
        "title": "TeenPatti Joker",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-010",
            "platform": "JILI",
        }
    },
    {
        "id": 28,
        "imgSrc": "",
        "title": "Callbreak Quick",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-013",
            "platform": "JILI",
        }
    },
    {
        "id": 29,
        "imgSrc": "",
        "title": "7up7down",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-011",
            "platform": "JILI",
        }
    },
    {
        "id": 30,
        "imgSrc": "",
        "title": "Dragon Tiger",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-012",
            "platform": "JILI",
        }
    },
    {
        "id": 31,
        "imgSrc": "",
        "title": "Sic Bo",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-012",
            "platform": "JILI",
        }
    }
    , {
        "id": 32,
        "imgSrc": "",
        "title": "SevenUpDown",
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-035",
            "platform": "JILI",
        }
    }
    , {
        "id": 33,
        "imgSrc": "",
        "title": "SevenUpDown",
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-SLOT-035",
            "platform": "JILI",
        }
    }
    , {
        "id": 34,
        "imgSrc": "",
        "title": "Andar Bahar",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-032",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 35,
        "imgSrc": "",
        "title": "AK47",
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-TABLE-002",
            "platform": "JILI",
        }
    }

    , {
        "id": 36,
        "imgSrc": "",
        "title": "Royal Fishing",
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-FISH-001",
            "platform": "JILI",
        }
    }

    , {
        "id": 37,
        "imgSrc": "",
        "title": "Jackpot Fishing",
        "gameInfo": {
            "gameType": "SLOT",
            "gameCode": "JILI-FISH-003",
            "platform": "JILI",
        }
    }
    , {
        "id": 38,
        "imgSrc": "",
        "title": "Teen Patti",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-TABLE-001",
            "platform": "JILI",
        }
    }
    , {
        "id": 39,
        "imgSrc": "",
        "title": "Mega Fishing",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-FISH-007",
            "platform": "FH",
        }
    }
    , {
        "id": 40,
        "imgSrc": "",
        "title": "All-star Fishing",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-FISH-009",
            "platform": "FH",
        }
    }
    , {
        "id": 41,
        "imgSrc": "",
        "title": "Happy Fishing",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "JILI-FISH-005",
            "platform": "FH",
        }
    }
    , {
        "id": 42,
        "imgSrc": "",
        "title": "sicbo",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-015",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 43,
        "imgSrc": "",
        "title": "7 Up 7 Down",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-028",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 44,
        "imgSrc": "",
        "title": "Coin Toss",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-036",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 45,
        "imgSrc": "",
        "title": "CardMatka",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-022",
            "platform": "KINGMAKER",
        }
    }

    , {
        "id": 47,
        "imgSrc": "",
        "title": "Andar Bahar",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-032",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 48,
        "imgSrc": "",
        "title": "NumberMatka",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-021",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 49,
        "imgSrc": "",
        "title": "Minesweeper",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-042",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 50,
        "imgSrc": "",
        "title": "32 Cards",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-039",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 51,
        "imgSrc": "",
        "title": "Cards Hi Lo",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-037",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 52,
        "imgSrc": "",
        "title": "Blackjack",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-038",
            "platform": "KINGMAKER",
        }
    }

    , {
        "id": 53,
        "imgSrc": "",
        "title": "Baccarat",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-026",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 54,
        "imgSrc": "",
        "title": "Bonus Dice",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-043",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 55,
        "imgSrc": "",
        "title": "Colour Game",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-050",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 56,
        "imgSrc": "",
        "title": "Heist",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-047",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 57,
        "imgSrc": "",
        "title": "KINGMIDAS",
        "isLobby": true,
        "gameInfo": {
            "platform": "KINGMAKER",
        }
    },
    {
        "id": 58,
        "imgSrc": "",
        "title": "Colour Game",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-050",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 59,
        "imgSrc": "",
        "title": "European Roulette",
        "gameInfo": {
            "gameType": "TABLE",
            "gameCode": "KM-TABLE-027",
            "platform": "KINGMAKER",
        }
    }
    , {
        "id": 60,
        "imgSrc": "",
        "title": "pragmatics play",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "PP-LIVE-001",
            "platform": "PP",
        }
    }
    , {
        "id": 61,
        "imgSrc": "",
        "title": "Cock Fight PHI",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "SV-LIVE-001",
            "platform": "SV388",
        }
    },
    {
        "id": 62,
        "imgSrc": "",
        "title": "HORSE BOOK",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "HRB-LIVE-001",
            "platform": "HORSEBOOK",
        }
    },
    {
        "id": 63,
        "imgSrc": "",
        "title": "VENUS",
        "isLobby": true,
        "gameInfo": {
            "platform": "VENUS",
        }
    },
    {
        "id": 64,
        "imgSrc": "",
        "title": "Live Casino",
        "isLobby": true,
        "gameInfo": {}
    }
    ,
    {
        "id": 62,
        "imgSrc": "",
        "title": "EVOLUTION",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "EVOLUTION-LIVE-001",
            "platform": "EVOLUTION",
        }
    },
    {
        "id": 63,
        "imgSrc": "",
        "title": "Aviator Spribe",
        "gameInfo": {
            "gameType": "EGAME",
            "gameCode": "SPRIBE-EGAME-001",
            "platform": "SPRIBE",
        }
    },
    {
        "id": 64,
        "imgSrc": "",
        "title": "Aviator Mobile",
        "gameInfo": {
            "gameType": "",
            "gameCode": "5b1b299bf4f04b9f84643730a882e682",
            "platform": "AVIATOR",
        }
    },
    {
        "id": 65,
        "imgSrc": "",
        "title": "JetX",
        "gameInfo": {
            "gameType": "",
            "gameCode": "cfcd1ed15cf62e9cde42482e2dcc0699f2f30d6f",
            "platform": "AVIATOR",
        }
    },
    {
        "id": 66,
        "imgSrc": "",
        "title": "JetX3",
        "gameInfo": {
            "gameType": "",
            "gameCode": "269a1ce0b5d04486a63139b9dd6dfe39",
            "platform": "AVIATOR",
        }
    },
    {
        "id": 67,
        "imgSrc": "",
        "title": "CricketX",
        "gameInfo": {
            "gameType": "",
            "gameCode": "9117a28ecefe54ebb9f812e1cbafdf0de9ace433",
            "platform": "AVIATOR",
        }
    },
    {
        "id": 68,
        "imgSrc": "",
        "title": "Aviatrix",
        "gameInfo": {
            "gameType": "",
            "gameCode": "9d9b5b34389337d4e43568b4ba2d56be97de447a",
            "platform": "AVIATOR",
        }
    },
    {
        "id": 62,
        "imgSrc": "",
        "title": "Crazy Time",
        "gameInfo": {
            "gameType": "LIVE",
            "gameCode": "EVOLUTION-LIVE-006",
            "platform": "EVOLUTION",
        }
    },
]

export const getCasinoObjVal = (gTitle, val) => {
    const fObj = casinoData.filter(i => i.title === gTitle)[0];
    if (val === 'platform' || val === 'gameCode' || val === 'gameType') {
        return fObj.gameInfo[val]
    } else {
        return fObj[val]
    }
};

export const getCasinoObj = (gTitle, getGameInfo) => {
    const fObj = casinoData.filter(i => i.title === gTitle)[0];
    return {
        id: fObj?.id,
        title: fObj?.title,
        isLobby: !!fObj?.isLobby,
        ...fObj.gameInfo
    }
};

export const casinoError = {
    9998: "System Busy",
    9999: "Fail",
    11: "Do not have this platform under your agent.",
    1000: "Invalid user Id",
    1001: "Account existed",
    1002: "Account is not exists",
    1004: "Invalid Currency",
    1005: "language is not exists",
    1006: "PT Setting is empty!",
    1007: "Invalid PT setting with parent!",
    1008: "Invalid token!",
    1009: "Invalid timeZone",
    1010: "Invalid amount",
    1011: "Invalid txCode",
    1012: "Has Pending Transfer",
    1013: "Account is Lock",
    1014: "Account is Suspend",
    1016: "TxCode already operation!",
    1017: "TxCode is not exist",
    1018: "Not Enough Balance",
    1019: "No Data",
    1024: "Invalid date time format",
    1025: "Invalid transaction status",
    1026: "Invalid bet limit setting",
    1027: "Invalid Certificate",
    1028: "Unable to proceed. please try again later.",
    1029: "invalid IP address.\n" +
        "It might be IP address did not whitelist yet, or AWC cannot identify your AgentID",
    1030: "invalid Device to call API.(Ex.IE)",
    1031: "System is under maintenance.",
    1032: "Duplicate login.",
    1033: "Invalid Game",
    1034: "Time does not meet.",
    1035: "Invalid Agent Id.",
    1036: "Invalid parameters.",
    1037: "Invalid customer setting.\n" +
        "It might be your target CallbackURL not yet passed AWC test, please contact us for assistant.",
    1038: "Duplicate transaction.",
    1039: "Transaction not found.",
    1040: "Request timeout.",
    1041: "HTTP Status error.",
    1042: "HTTP Response is empty.",
    1043: "Bet has canceled.",
    1044: "Invalid bet.",
    1045: "Add account statement failed.",
    1046: "Transfer Failed! Please contact customer support immediately. Sorry for any inconvenience caused.",
    1047: "Game is under maintenance.",
    1054: "Invalid Platform",
    1056: "[any parameter]was empty",
}

export const casinoErrorMsg = (status) => {
    const fMsg = Object.keys(casinoError).filter((s) => s == status);
    const key = fMsg[0];
    const getErr = (fMsg.length > 0 && key) ? casinoError[key] : null;
    return getErr ? getErr : 'Unknown error';
}
