import React, { useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import App from '../images/sprite/btn-appdl-android.png'
import betfair from '../images/sprite/logo-betfair.png'


export default function Support(props) {
	const [Socialicon, setSocialicon] = useState(0);



	return (
		<React.Fragment>
			<footer className="cls_footer_new">
  <div className="footer-top">
    <div className="container">
      <div className="row">
        <div
          id="policies"
          className="col-md-456 col-lg-4 footer-about wow fadeInUp"
        >
      
          <p />
          <div className="footercolumn">
            <p className="footer-links">
              <a  className="ui-link" onClick={() => { props.handlepolicy(1, true) }}>
                Privacy Policy
              </a>
            </p>
            <p className="footer-links">
              <a  className="ui-link" onClick={() => { props.handlepolicy(3, true) }}>
                Rules and Regulations
              </a>
            </p>
            <p className="footer-links">
              <a  onClick={() => { props.handlepolicy(4, true) }} className="ui-link">
                KYC
              </a>
            </p>
            <p className="footer-links">
              <a  className="ui-link" onClick={() => { props.handlepolicy(5, true) }}>
                Responsible Gaming
              </a>
            </p>
            <p className="footer-links">
              <a href="/tnc" className="ui-link" onClick={() => { props.handlepolicy(2, true) }}>
                Terms and Conditions
              </a>
            </p>
            <p className="footer-links">
              <a href="/au" className="ui-link" onClick={() => { props.handlepolicy(6, true) }}>
                About Us
              </a>
            </p>
          </div>
        </div>
        <div
          id="socials"
          className="col-md-4 col-lg-3 footer-social wow fadeInUp"
        >
          <p id="social-icons">
            <a
              data-class="supportDetail_facebook"
              href="https://www.facebook.com/groups/1821049225013152/?ref=share&mibextid=NSMWBT"
              className="support_facebook support-facebook ui-link open  supportService"
              target="_blank"
            >
              <img
                src="https://d2g8jl9s27zu.cloudfront.net/bicdn/footer/m_images/facebook.svg"
                title="Facebook"
              />
            </a>
            <a
              data-class="supportDetail_whatsapp"
              href="https://wa.me/+447529620657"
              className="support_whatsapp support-whatsapp ui-link  supportService"
              target="_blank"
            >
              <img
                src="https://d2g8jl9s27zu.cloudfront.net/bicdn/footer/m_images/whatsapp.svg"
                title="Whatsapp"

              />
            </a>
            <a
              data-class="supportDetail_telegram"
              href="https://t.me/Iqbalfahat"
              className="support_telegram support-telegram ui-link  supportService"
              target="_blank"
            >
              <img
                src="https://d2g8jl9s27zu.cloudfront.net/bicdn/footer/m_images/telegram.svg"
                title="Telegram"
                
              />
            </a>
            <a
              data-class="supportDetail_twitter"
              href="#"
              className="support_twitter support-twitter ui-link  supportService"
              target="_blank"
            >
              <img
                src="https://d2g8jl9s27zu.cloudfront.net/bicdn/footer/m_images/x_twitter.svg"
                title="x"
              />
            </a>
            <a
              data-class="supportDetail_instagram"
              href="#"
              className="support_instagram support-instagram ui-link supportService"
              target="_blank"
            >
              <img
                src="https://d2g8jl9s27zu.cloudfront.net/bicdn/footer/m_images/instagram.svg"
                title="Instagram"
              />
            </a>
            <a
              data-class="supportDetail_youtube"
              href="#"
              className="support_youtube support-youtube ui-link supportService"
              target="_blank"
            >
              <img
                src="https://d2g8jl9s27zu.cloudfront.net/bicdn/footer/m_images/youtube.svg"
                title="Youtube"
              />
            </a>
          </p>
        </div>
        <p className="footer-text">
          <i />
          fastbat is the world's leading online sports betting and casino
          gaming platform that aims at offering an authentic, immersive and
          seamless gaming experience to its players. There are more than 30
          sports to choose from in total for EXCHANGE, which include cricket
          (IPL, CPL, PSL, World Cup), soccer, tennis and many more. Players can
          play LIVE CASINO on fastbat with LIVE DEALERS dealing all games
          including Roulette, Blackjack, Teen Patti, Andar Bahar, Poker, Slots
          and more. fastbat is a trusted casino and offers fast deposits and
          withdrawals with round the clock support on email and live chat. All
          new players at fastbat are offered a generous welcome bonus on
          their first deposit! Welcome to the The King of Online Betting &amp;
          Casino, fastbat!
        </p>
        <hr />
        {/* <div
          className="marquee hm_foo_pg_sc"
          style={{ paddingBottom: "115.062px" }}
        >
          <ul className="marquee-content">
            <li className="marquee-item">
              <img
                className="marquee-image"
                src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/footer/payment icons-01.png"
                alt="payment option"
              />
            </li>
            <li className="marquee-item">
              <img
                className="marquee-image"
                src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/footer/payment icons-18.png"
                alt="payment option"
              />
            </li>
            <li className="marquee-item">
              <img
                className="marquee-image"
                src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/footer/payment icons-19.png"
                alt="payment option"
              />
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  </div>
</footer>

{/* 
			<div id="supportWrap" className="support-wrap">
				<div className="support-service">
					<a id="support_email" onClick={() => { setSocialicon(0) }} className={`support-mail ui-link ${(Socialicon === 0) ? "open" : "null"}`}>
						<img src={Transparent} title="Email" /></a>
					<a id="support_whatsapp" onClick={() => { setSocialicon(1) }} className={`support-whatsapp ui-link ${(Socialicon === 1) ? "open" : "null"}`}>
						<img src={Transparent} title="WhatsApp" /></a>
					<a
						id="support_telegram" onClick={() => { setSocialicon(2) }} className={`support-fb ui-link ${(Socialicon === 2) ? "open" : "null"}`}>
						<img src={Transparent} title="facebook" /></a>
					<a id="support_skype" onClick={() => { setSocialicon(3) }} className={`support-skype ui-link ${(Socialicon === 3) ? "open" : "null"}`} >
						<img src={Transparent} title="Skype" /></a>
					<a
						id="support_instagram" onClick={() => { setSocialicon(4) }} className={`support-ig ui-link ${(Socialicon === 4) ? "open" : "null"}`} >
						<img src={Transparent} title="Instagram" /></a>
				</div>
				<div className="support-info">
					<div id="supportDetail_email" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
						{Socialicon === 0 && <a className="ui-link" href="https://t.me/fastbat_bet"><span>fastbat_bet</span></a>
						}</div>

					<div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 1) ? "open" : "null"}`} >
						{Socialicon === 1 && <a class="ui-link" href="https://wa.me/+971524225063"><span>0000</span></a>}
					
					</div>
					<div id="supportDetail_telegram" className={`support-detail ${(Socialicon === 2) ? "open" : "null"}`} >
						{Socialicon === 2 && <a class="ui-link" href="#"><span>fastbatfacebook</span></a>}
					</div>
					<div id="supportDetail_skype" className={`support-detail ${(Socialicon === 3) ? "open" : "null"}`} >
						{Socialicon === 3 && <a class="ui-link"><span>fastbatofficial</span></a>}
					</div>
					<div id="supportDetail_instagram" className={`support-detail ${(Socialicon === 4) ? "open" : "null"}`} >
						{Socialicon === 4 && <a class="ui-link"><span>officialfastbat</span></a>}
					</div>
				</div>
			</div>

			<ul _ngcontent-njs-c67="" class="mob-inform-link" style={{ display: ' block' }}>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(4, true) }} >KYC</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(6, true) }}>About Us</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(1, true) }}>Privacy Policy</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(5, true) }}>Responsible Gaming</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(2, true) }}>Terms and Conditions</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(8, true) }}>Underage Gaming Policy</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(7, true) }}>Self-Exclusion Policy</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(3, true) }}>Rules and Regulations</a></li>

			</ul>

			<div _ngcontent-njs-c67="" className="extra-wrap">
				<div _ngcontent-njs-c67="" id="powerWrap" className="power-content-b" style={{ display: 'block' }}>
					<span _ngcontent-njs-c67="" >Powered by</span><img src={betfair} />
				</div>
				<div _ngcontent-njs-c67="" className="appdl-link-android" style={{ display: 'block' }}>
					<a _ngcontent-njs-c67="" href="#"><img src={App} alt /></a>
					<p _ngcontent-njs-c67="" >v1 - 2022-08-06 - 4.0MB</p>
				</div>
			</div> */}

		</React.Fragment>
	)
}











{/* <a id="support_email"  
		onClick ={()=>{setSocialicon(0)}} 
		className={`support-mail ui-link ${(Socialicon===0 )? "openn": "null"}`}
		><img src={Transparent} title="Email"/></a> */}

{/* <a id="support_skype"   
		onClick ={()=>{setSocialicon(3)}} 
		className={`support-skype  ${(Socialicon===3 )? "openn": "null"}`}
		><img src={Transparent} title="Skype"/></a>
		
		
		<a id="support_instagram"  
		onClick ={()=>{setSocialicon(4)}} 
		className={`support-ig  ${(Socialicon===4 )? "openn": 
		"null"}`}
		><img src={Transparent} title="Instagram"/></a> */}
{/* { Socialicon ===0 &&
		<div id="supportDetail_email" 
		className={`support-detail ${(Socialicon===0 )? "open": "null"}`}
		><a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>info@fastbat.games</span></a></div>
	} */}


{/* 		
		<div id="supportDetail_skype" className={`support-detail ${(Socialicon===3 )? "open": "null"}`}>
		{ Socialicon ===3 &&
			<a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>fastbatofficial</span></a>
		}
			</div>
		
		
		<div id="supportDetail_instagram" className={`support-detail ${(Socialicon===4 )? "open": "null"}`}>
		{ Socialicon ===4 &&
			
			<a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>officialfastbat</span></a>
			}
			</div> */}
